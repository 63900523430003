import React, { useEffect, useState } from 'react';
import './tasks.scss';

import TaskCard from './TaskCard';

function Tasks(props) {
  const { data, swipeActions } = props;
console.log(swipeActions)
  return (
    <div className="Tasks fadeIn" {...swipeActions}>
      <div className="Tasks__Top">
        <div className="Tasks__Top--Heading">How to earn points?</div>
        <div className="Tasks__Top--SubHeading">
          You can earn points by doing some simple tasks and teaching your
          students
        </div>
      </div>
      <div className="Tasks__Body">
        {data?.map((task, i) => {
          return <TaskCard data={task} key={i} />;
        })}
      </div>
    </div>
  );
}

export default Tasks;
