import React from 'react';
import './Leaderboard.scss';
import LeaderBoardTop from "./LeaderBoardTop"
import Ranking from './Ranking';
import { directDeepLink, SOURCE_WEBVIEW } from '../../utils';

function Leaderboard(props) {
  const { data, swipeActions } = props;
  const topData = data?.teamRankings?.slice(0, 3)
  const belowData = data?.teamRankings?.slice(3, data?.teamRankings?.length)
  console.log(topData, belowData)
  return (
    <div className="Leaderboard fadeIn" {...swipeActions}>
      <div className="Leaderboard__Body">
        {/* <div className="Leaderboard__Heading">Ranking</div>
        <div className="Leaderboard__Subheading">
          Know where you stand among other institutes
        </div> */}
        <LeaderBoardTop data={topData} info={data?.info} />
        <Ranking data={belowData} />

        {data?.awardsImage?.imgUrl?.imgUrl ? (
          <img
            onClick={() => directDeepLink(data?.awardsImage?.deeplink, SOURCE_WEBVIEW)}
            className="Leaderboard__Body--Prize"
            src={data?.awardsImage?.imgUrl?.imgUrl}
            width="100%"
            alt=""
          />
        ) : (
          ''
        )}
      </div>
    </div>
  );
}

export default Leaderboard;
