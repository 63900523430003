import React from 'react';

import Leaderboard from '../Leaderboard';
import MilestonePage from '../Milestone';
import NewRewards from '../NewRewards';
import Tasks from '../Tasks';
import Tools from '../Tools';
import Checklist from '../CheckList'

function Content(props) {
  const { data, activeTab, swipeActions, setNav } = props;
  console.log("navData>>>>",data)
  switch (activeTab?.key) {
    case 'leaderboards':
      return <Leaderboard data={data?.rankings} swipeActions={swipeActions} />;
    case 'milestones':
      return <MilestonePage data={data?.peakDay} swipeActions={swipeActions} />;
    case 'tasks':
      return <Tasks data={data?.statCard} swipeActions={swipeActions} />;
    case 'rewards':
      return (
        <NewRewards data={data.awardsCard} swipeActions={swipeActions} setNav={setNav} />
      );
    case 'tools':
      return <Tools data={data?.toolsCard} swipeActions={swipeActions} />;
    case 'checklist':
      return <Checklist data={data?.checklist} swipeActions={swipeActions}/>
    default:
      return <div></div>;
  }
}

export default Content;
