import React from 'react';
import { directDeepLink, SOURCE_HOME, SOURCE_WEBVIEW } from '../../../utils';
import { webEngageSaleServiceRevamp } from '../../../webEngage/service';
import * as weEvents from "../../../webEngage/event";

function CardFooter(props) {
  const { data, footerTextStyle = {} } = props;
  return (
    <div className="CardFooter" style={{ ...props.styleFooter }}>
      {!!data?.text && <div
        style={{ ...footerTextStyle }}
        className="CardFooter__Text">
        {data?.text}
      </div>}
      <div
        onClick={() => {

          data?.clickEvent && data?.clickEvent()
          console.log("onClick")
          directDeepLink(data?.deepLink, SOURCE_HOME)
        }}
        className="CardFooter__Button"
        style={{ backgroundColor: data?.button?.bgColor }}
      >
        {data?.button?.text}
      </div>
    </div>
  );
}

export default CardFooter;
