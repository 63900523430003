import { useEffect } from 'react';
import './App.scss';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import Home from './components/Home/';
import Layout from './components/Layout';
import RewardsPage from './components/RewardsPage';
import { getQuery } from './utils';
import GrowthInsights from './components/GrowthInsights';

function App() {
  useEffect(() => {
    const token = getQuery()["token"];
    if (token) {
      localStorage.setItem('campaignToken', token);
    }
  }, []);

  return (
    <div className="App">
      <BrowserRouter>
        <Switch>
          <Route exact path="/" component={Home} />
          <Route exact path="/details/:tab" component={Layout} />
          <Route exact path="/rewards" component={RewardsPage} />
          <Route exact path="/growth" component={GrowthInsights} />
        </Switch>
      </BrowserRouter>
    </div>
  );
}

export default App;
