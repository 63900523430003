export function webEngageSaleServiceRevamp(action, extraProperties = {}) {


    let userData = JSON.parse(localStorage.getItem("saleData"))

    let token = localStorage.getItem("campaignToken")
    var base64Url = token.split('.')[1];
    var decodedValue = JSON.parse(window.atob(base64Url));

    let is_user_id_even = decodedValue.id % 2 === 0 ? true : false

    // last_action = window?.last_action;

    let commonProperties = {
        org_id: Number(userData.orgId),
        org_name: String(userData.orgName),
        user_type: Number(decodedValue.type),
        user_name: String(decodedValue.name),
        cp_user_id: Number(decodedValue.id),
        is_user_id_even: Boolean(is_user_id_even),
        cp_sdk_source: 'web',
        last_action: String(window?.last_action) || null

    }
    window.last_action = action;

    console.log("webengage++++", action, {
        ...commonProperties,
        ...extraProperties,


    });
    window.webengage.track(action, {
        ...commonProperties,
        ...extraProperties,

    });
}












