import React from "react";

import ToolsArrow from "../../Resources/images/toolsArrow.png";
import { directDeepLink, SOURCE_HOME, SOURCE_WEBVIEW } from "../../utils";
import Image from "../../Resources/images/youtubeBG.png";
import arrowIcon from "../../Resources/images/arrowRight.png";
import ToolImg from "../../Resources/images/carnival/toolImg.png";
import { webEngageSaleServiceRevamp } from "../../webEngage/service";
import * as webEvents from "../../webEngage/event";
function ToolCard(props) {
  const { data, isTab, idx, style } = props;
  return (
    <div
      className="ToolCard"
      style={{
        // background: "#661744",//idx === 0 ? `url(${Image})` : data?.bgColor,
        background: "#3D2072",
        filter: "drop-shadow(0px 2px 4px rgba(0, 0, 0, 0.2))",
        backdropFilter: "blur(40px)",
        /* Note: backdrop-filter has minimal browser support */

        borderRadius: "16px",
        gridColumn: idx === 0 ? "1/-1" : "",
      }}
      onClick={() => {
        webEngageSaleServiceRevamp(webEvents.SALE_TOOLS_CLICK, {});
        directDeepLink(data?.deeplink, isTab ? SOURCE_WEBVIEW : SOURCE_HOME);
      }}
    >
      <div style={style}>
        {idx !== 0 && (
          <img className="ToolCard--Icon" src={data?.icon} alt="ICON" />
        )}
        <div className="ToolCard__Label">{data?.text}</div>
        {idx === 0 && (
          <div className="ToolCard--Text">
            Start your YouTube journey & grow like never before!
          </div>
        )}
        <img className="ToolCard--Arrow" src={arrowIcon} alt="" />
      </div>
      {idx === 0 && <img className="ToolCard--image" src={ToolImg} />}
    </div>
  );
}

export default ToolCard;
