import React from 'react';
import './RewardCard.scss';
import Coin from '../../../Resources/images/Coin.svg';
const RewardCard = ({ onClick, value }) => {
  return (
    <div className="RewardCard__Wrapper" onClick={onClick}>
      <div className="RewardCard">
        <img src={ value.imgUrl} alt="icon" />
      </div>
      <div className="Reward__PointsCard">
        <div className="Reward__PointsCard__text">{value?.points} pts</div>
        <div className="Reward__PointsCard__image">
          <img src={Coin} className="Reward__PointsCard__image--coin" />
        </div>
      </div>
    </div>
  );
};

export default RewardCard;
