import Promote from './images/Promote.svg';
import Contact from './images/Contact.svg';
import StoreAcademy from './images/StoreAcademy.svg';

const posterMaker =
  localStorage.getItem('TeamId') == 8 || localStorage.getItem('TeamId') == 9
    ? 'https://classplusapp.com/gold-rush-sale'
    : localStorage.getItem('TeamId') == 3 ||
      localStorage.getItem('TeamId') == 4 ||
      localStorage.getItem('TeamId') == 5
    ? 'https://classplusapp.com/weekendBonanza'
    : 'https://classplusapp.com/big-summer-sale';

// const whatsappText =
//   localStorage.getItem('TeamId') == 6 ||
//   localStorage.getItem('TeamId') == 1 ||
//   localStorage.getItem('TeamId') == 2 ||
//   localStorage.getItem('TeamId') == 7
//     ? `Hi Classplus team
// I would like to know how can I win these exciting awards in July.`
//     : `Hi Classplus team I would like to know how can I grow my revenue in The Big Summer Sale campaign`;

// const whatappNumber =
//   localStorage.getItem('TeamId') == 6 ? '918588025648' : '919711909188';

export const playLists = [
  {
    name: 'How to increase your app downloads?',
    image:
      'https://cp-assets-public.s3.amazonaws.com/campaignManager/YazIuEGnL9RZSBg2',
    url: {
      screen: 'UTIL_BROWSER',
      paramOne:
        'https://www.youtube.com/watch?v=BsDQ8gp4g1s&list=PL1AInJ3odbpmgroDv97xt5SkJteJH27-b',
      paramTwo: '',
      paramThree: '',
    },
  },
  {
    name: 'How to boost your revenue?',
    image:
      'https://cp-assets-public.s3.amazonaws.com/campaignManager/NXMQNRRlS4iTSJNS',
    url: {
      screen: 'UTIL_BROWSER',
      paramOne:
        'https://www.youtube.com/watch?v=Da1vs8i0Z_E&list=PL1AInJ3odbpnk1v4UwWpWMt6_Pm7ERBx0',
      paramTwo: '',
      paramThree: '',
    },
  },
  {
    name: 'How to keep your store updated?',
    image:
      'https://cp-assets-public.s3.amazonaws.com/campaignManager/qe4i4-pOFj611Lzk',
    url: {
      screen: 'UTIL_BROWSER',
      paramOne:
        'https://www.youtube.com/playlist?list=PL1AInJ3odbpkCMQy2S6WVaAGOT7_Qdbds',
      paramTwo: '',
      paramThree: '',
    },
  },
  {
    name: 'How to Go LIVE on your Store courses easily!',
    image:
      'https://cp-assets-public.s3.amazonaws.com/campaignManager/tT_wGIeDSWz3u1N_',
    url: {
      screen: 'UTIL_BROWSER',
      paramOne: 'https://www.youtube.com/watch?v=BUt11ROoGUU',
      paramTwo: '',
      paramThree: '',
    },
  },
];

// export const insightArray = [
//   {
//     heading: 'YOUTUBE VIDEOS',
//     text: playLists.one.name,
//     bgColor: '#768BD3',
//     button: 'Promote Now',
//     buttonColor: '#5062A2',
//     deepLink: `UTIL_VIDEO,${playLists.one.url},'youtube-exo'`,
//     image: Promote,
//   },
//   {
//     heading: 'YOUTUBE VIDEOS',
//     text: playLists.two.name,
//     bgColor: '#C97AA5',
//     button: 'Watch Now',
//     buttonColor: '#B05788',
//     deepLink: `UTIL_VIDEO,${playLists.two.url},youtube-exo`,
//     image: StoreAcademy,
//   },
//   {
//     heading: 'YOUTUBE VIDEOS',
//     text: playLists.three.name,
//     bgColor: '#768BD3',
//     button: 'Promote Now',
//     buttonColor: '#5062A2',
//     deepLink: `UTIL_BROWSER,${playLists.three.url},`,
//     image: Promote,
//   },
//   {
//     heading: 'YOUTUBE VIDEOS',
//     text: playLists.four.name,
//     bgColor: '#C97AA5',
//     button: 'Watch Now',
//     buttonColor: '#B05788',
//     deepLink: `UTIL_VIDEO,${playLists.four.url},youtube-exo`,
//     image: StoreAcademy,
//   },
// ];
