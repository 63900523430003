import React from 'react';
import "./Checklist.scss"
import checkListIcon from "../../Resources/images/checkListIcon3.png"
import checkListImg from "../../Resources/images/carnival/checkListImg.png"

export default function Checklist(props) {
    const { data, swipeActions } = props;
    return <div className="Checklist" {...swipeActions}>
        <div className="Checklist__Image">
            <img src={checkListImg} />
        </div>
        {
            data?.map((el, index) => {
                return <div>
                    <div className="Checklist__header">{el?.title}</div>
                    <div className="Checklist__subHeader">
                        <div className="Checklist__textSubHeader">
                            <div><img src={checkListIcon} /></div>
                            <div className="Checklist__subText">
                                {el?.body?.[0]}
                            </div>
                        </div>
                        <div className="Checklist__barSeperator" />
                        <div className="Checklist__textSubHeader">
                            <div><img src={checkListIcon} /></div>
                            <div className="Checklist__subText">
                                {el?.body?.[1]}
                            </div>
                        </div>
                    </div>
                </div>
            })
        }
    </div>
}