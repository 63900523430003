import React from 'react';
import './progressBar.scss';

function ProgressBar(props) {
  const { width, background, thick } = props;

  return (
    <div className="ProgressBar"
      style={{ height: `${thick || 8}px` }}>
      <span className="ProgressBar__Indicator" style={{ width: `${width}%` }}>
        <span
          className="ProgressBar__Indicator--Fill"
          style={{ background: background }}

        ></span>
      </span>
    </div>
  );
}

export default ProgressBar;
