import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import CardFooter from '../CardFooter/CardFooter';
import holiMileStone from "../../../Resources/images/holiMileStone.png"
import BannerImage from '../../../Resources/images/miles.png';
import MilestoneSection from '../MilestoneSection';
import mileStoneCardHeaderImg from "../../../Resources/images/baisakhi/MilestoneText.svg";
import HomeText from "../../../Resources/images/south/MilestonesText.svg";
import SingleRibbon from "../../../Resources/images/baisakhi/SingleRibbon.svg";
import MileStoneSlideImg from "../../../Resources/images/summerSuperSaverSale/mileStoneSlideImg.png";
import MheckListSlideTopBg from "../../../Resources/images/summerSuperSaverSale/mileStoneSlideTopBg.png"
import BgRainCard from "../../../Resources/images/BgRainCard.png"
import CheckListSlideImg from "../../../Resources/images/carnival/checkListSlideImg.png"
import Mogu from "../../../Resources/images/baisakhi/MoguDesign.svg";
import { webEngageSaleServiceRevamp } from '../../../webEngage/service';
import * as webEvents from "../../../webEngage/event"
import lights from './lights.gif'

const lottieStyle = {
  position: "absolute",
  right: '-10px',
  bottom: "-8px",
  width: "50%",
};
const lottieStyleSouth = {
  position: "absolute",
  right: '10px',
  bottom: '10px',
  width: '37%',
}
const lottie = (isNorth) => {
  return (
    <div style={isNorth ? lottieStyle : lottieStyleSouth}>
      <lottie-player
        src={
          isNorth
            ? "https://assets6.lottiefiles.com/packages/lf20_sgn7zslb.json"
            : "https://assets6.lottiefiles.com/packages/lf20_DMgKk1.json"
        }
        background="transparent"
        speed="1"
        style={{ width: "100%", height: "100%" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};
function MilestoneSlide(props) {
  const { data, isNorth } = props;
  console.log(data)
  const banner = {
    text: 'Win a special prize today',
    image: BannerImage,
    gridTemplateColumns: '1fr 1fr',
    imgMargin: '10px 12px 10px 0',
  };
  const imagePostion = {
    right: "13%",
    top: "-11%",
    width: "12rem",
  }

  const customStyle = {
    padding: "14px 20px 20px 20px"
  }

  const footer = {
    button: {
      text: 'View Milestones',
      bgColor: '#FDCA00',

    },
    deepLink: data?.deeplink,
    clickEvent: () => webEngageSaleServiceRevamp(webEvents.SALE_VIEW_MILESTONES_CLICK, {})
  };

  const otherBannerStyle = {
    // paddingTop: "1.4rem"
    background: `url(${MheckListSlideTopBg}) no-repeat`
  }

  return (
    <div
      className="Slide"
      style={{
        backgroundImage: `url(${BgRainCard})`,
        backgroundSize: "cover",
        backgroundPosition: "center",
        // backgroundColor: '#1C3767',
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        // borderRadius: "8px",
        // margin: "2px",
        // border: '2px solid rgb(253, 202, 0)'
      }}
    >
      {/* <img
        src={lights}
        width="100%"
        style={{
          width: '167%',
          marginLeft: '65%',
        }}
      /> */}

      <PeaknStatsCard
        data={banner}
        image={MileStoneSlideImg}
        // backgroundImage={isNorth?mileStoneCardHeaderImg:HomeText}
        // imagePostion={imagePostion}
        // topRightImg={SingleRibbon}
        otherBannerStyle={otherBannerStyle}
        text="Win a special prize today"
        // image={CheckListSlideImg}
        rightBkgImg={isNorth ? Mogu : ''}
        imagePostion={imagePostion}
        margin={-143}
      >
        {/* {lottie(isNorth)} */}
      </PeaknStatsCard>
      <div
        style={{
          // background: "linear-gradient(180deg, #240675 0%, #1C055A 100%)",
        }}
      >
        <MilestoneSection
          data={data?.peakDay?.mileStonesToBeAchieved}
          textData={data?.peakDay}
        />
        {/* {console.log("data?.peakDay", data?.peakDay)} */}
        <CardFooter data={footer} styleFooter={customStyle} />
      </div>
    </div>
  );
}

export default MilestoneSlide;
