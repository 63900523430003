
import React,{useState} from 'react';
import './LeaderBoardTop.scss';
import rank1img from "../../../Resources/images/rank1img.png"
import rank2img from "../../../Resources/images/rank2img.png"
import rank3img from "../../../Resources/images/rank3img.png"
import firstRank from "../../../Resources/images/TopRank/firstRank.png"
import secondRank from "../../../Resources/images/TopRank/secondRank.png"
import thirdRank from "../../../Resources/images/TopRank/thirdRank.png"

import ThirdBackGround from "../../../Resources/images/TopRank/thirdBackGround.png"
import blueGradient from "../../../Resources/images/TopRank/blueGradient.png"
import violet from "../../../Resources/images/TopRank/violet.png"
import violetGradient2 from "../../../Resources/images/TopRank/violetGradient2.png"
import violetGradient from "../../../Resources/images/TopRank/violetGradient.png"
import firstBackground1 from "../../../Resources/images/TopRank/firstBackGround1.png"
// import FirstBackground2 from "../../../Resources/images/TopRank/firstBackground2.png"
import leaderBoardInfo from "../../../Resources/images/newInfoIcon.png"
export default function LeaderBoardTop({data,info}) {
    const [showInfow,setShowInfo] = useState(false)
    return (<div>
        <div className="leaderBoardTop">
            {/* <div className="leaderBoardTop__left">
                <div className="leaderBoardTop__left-gradient"></div>
                <div className="leaderBoardTop__left-solid"></div>
                <div className="leaderBoardTop__left-solid2"></div>
            </div>
            <div className="leaderBoardTop__center">
                <div className="leaderBoardTop__center-solid1"></div>
                <div className="leaderBoardTop__center-solid2"></div>
            </div>
            <div className="leaderBoardTop__right">right</div> */}
                         <img src={firstBackground1} className="leaderBoardTop__yellow"/>
            {/* <img src={FirstBackground2} className="leaderBoardTop__yellowGradient"/> */}
            <img src={violet} className="leaderBoardTop__red" />
            <img src={violetGradient} className="leaderBoardTop__redGradient"/>
            <img src={violetGradient2} className="leaderBoardTop__red2"/>
            <img src={ThirdBackGround} className="leaderBoardTop__orange"/>
            {/* <img src={blueGradient} className="leaderBoardTop__orangeGradient"/> */}
            <img src={data[0]?.orgLogo} className="leaderBoardTop__rank1"/>
            <img src={data[1]?.orgLogo} className="leaderBoardTop__rank2"/>
            <img src={data[2]?.orgLogo} className="leaderBoardTop__rank3"/>

            <img src={firstRank} className="leaderBoardTop__firstRank"/>
            <img src={secondRank} className="leaderBoardTop__secondRank"/>
            <img src={thirdRank} className="leaderBoardTop__thirdRank"/>
            <div className="leaderBoardTop__institue1">{data[0]?.orgName}</div>
            <div className="leaderBoardTop__institue2">{data[1]?.orgName}</div>
            <div className="leaderBoardTop__institue3">{data[2]?.orgName}</div>
            <div className="leaderBoardTop__institue1-point">{data[0]?.points}<span className="leaderBoardTop__institue1-point-text">points</span></div>
            <div className="leaderBoardTop__institue2-point">{data[1]?.points}<span className="leaderBoardTop__institue1-point-text">points</span></div>
            <div className="leaderBoardTop__institue3-point">{data[2]?.points}<span className="leaderBoardTop__institue1-point-text">points</span></div>
            {showInfow&&<div className="leaderBoardTop__infoText">{info}</div>}
            <img className="leaderBoardTop__infoIcon" src={leaderBoardInfo} onClick={()=>setShowInfo(!showInfow)}/>
            {/* <div className="leaderBoardTop__institue2-point-text">points</div>
            <div className="leaderBoardTop__institue3-point-text">points</div> */}
        </div>
    </div>)
}