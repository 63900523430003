const baseUrl_devTesting = "http://store-gcp-sarthak.classplus.co";
// "https://store.avinash.dev.classplus.co";
//'http://store-gcp-sparsh.classplus.co';
const baseUrl_preprod = "http://store-preprod-internal.classplus.co";
const baseUrl_prod = "https://api.classplusapp.com";
const baseUrl_preprodTest = "https://store-preprod-test.classplus.co";

// FOR ANY CHANGES IN TARGET, CHANGE THE BELOW VALUE - childrens day
const base_Url = baseUrl_prod;

// CONSTANT
export const SOURCE_HOME = "SOURCE_HOME";
export const SOURCE_WEBVIEW = "SOURCE_WEBVIEW";

export const GET = "GET";
export const POST = "POST";

// FUNCTION TO CAPTURE QUERY PARAMETERS, initialized by '?', separated by '&'
export const getQuery = () => {
  let b = window.location.search
    .slice(1)
    .split("&")
    .map((qStr) => qStr.split("="))
    .reduce((acc, inc) => {
      acc[inc[0]] = inc[1];
      return acc;
    }, {});
  return b;
};

const token = getQuery()["token"] || localStorage.getItem("campaignToken");
// "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnSWQiOjEsInR5cGUiOjMsIm1vYmlsZSI6IjkxOTg3MTI1MTgwNSIsIm5hbWUiOiJzdHJpbmciLCJlbWFpbCI6InVzZXI5MUBleGFtcGxlLmNvbSIsImlhdCI6MTY1MjE2Mjc2MiwiZXhwIjoxNjUzODkwNzYyfQ.k2ihfzbgF924214u7sZ8cyN3bKOqubwGyzFGdsuHZ3dUhl83X-ITHaGOI1L9fzWw"
// "eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6Mjg5OTk0LCJvcmdJZCI6MTcwLCJuYW1lIjoiR3VuamFuIEd1cHRhIiwiZW1haWwiOiJndW5qYW5AY2xhc3NwbHVzYXBwcC5jb20iLCJtb2JpbGUiOiI5MTk3MTExNTMwNzkiLCJ0eXBlIjozLCJpYXQiOjE2NDgxMjQ0NTksImV4cCI6MTY0ODcyOTI1OX0.Nf6pS8G74I3QD8RBkEpRkKKQOidnHeG-k0AZb0NR6zlp6ix5xNFr3YirQrTJEsZj"
// const token =
//   'eyJhbGciOiJIUzM4NCIsInR5cCI6IkpXVCJ9.eyJpZCI6MSwib3JnSWQiOjEsIm5hbWUiOiJNYXN0ZXIgVHV0b3IiLCJlbWFpbCI6InNhazIyMkBnbWFpbC5jb20iLCJtb2JpbGUiOiI5MTk4NzEyNTE4MDUiLCJ0eXBlIjozLCJpYXQiOjE2Mjk3NTYxMzMsImV4cCI6MTYzMTQ4NDEzM30.ZAX1OBbWw8ICSOoYCZ3ggRqjrma448DIfx3cFQix5CWRzwueVC2jm7IOE5AhJ9NI';
// API CALL FUNCTION
export const makeApiCal = (action) => {

  // PARAMETERS - method, route, storeData, catchError, body
  switch (action.method) {
    case GET:
      return fetch(`${base_Url}/${action.route}`, {
        method: GET,
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === "success"
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => {
          action.catchError(err);
        });
    case POST:
      return fetch(`${base_Url}/${action.route}`, {
        method: POST,
        headers: {
          "x-access-token": token,
          "Content-Type": "application/json",
          Connection: "keep-alive",
        },
        body: JSON.stringify(action.body),
      })
        .then((res) => res.json())
        .then((res) => {
          res?.status === "success"
            ? action.storeData(res?.data)
            : action.catchError(res.message);
        })
        .catch((err) => action.catchError(err));
    default:
      return;
  }
};
// DEEPLINK FUNCTION(s)
// Takes in single parameter, a string of 3 values i.e. `SCREEN_NAME,paramOne,paramTwo`

// WEBVIEW
// export const directDeepLink = (url, source, showAlert) => {
//   const action = {
//     method: POST,
//     route: "v2/storeDeeplink",
//     body: { deeplink: url },
//     storeData: (e) => console.log(e),
//     catchError: (e) => console.error(e),
//   };
//   switch (source) {
//     case SOURCE_HOME:
//       return () => {
//         alert("first")
//         window?.mobile?.exceuteDeeplink(JSON.stringify(url));
//         alert("second")
//         window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
//           JSON.stringify(url)
//         );
//         alert("third")
//         window?.mobile?.exceuteDeeplink(JSON.stringify(url));
//         alert("fourth")
//         makeApiCal(action);
//       };
//     case SOURCE_WEBVIEW:
//       return () => {
//         window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage(
//           `${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
//         );

//         window?.mobile?.onDeeplinkExecuted(
//           `${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
//         );

//         window?.mobile?.onDeeplinkExecutedV2(JSON.stringify(url));

//         makeApiCal(action);
//       };
//     default:
//       return;
//   }
// };

export const directDeepLink = (url, source, showAlert) => {
  const action = {
    method: POST,
    route: 'v2/storeDeeplink',
    body: { deeplink: url },
    storeData: (e) => console.log(e),
    catchError: (e) => console.error(e),
  };
  switch (source) {
    case SOURCE_HOME:
      makeApiCal(action);
      window?.webkit?.messageHandlers?.onDeeplinkExecutedV2?.postMessage(
        JSON.stringify(url)
      );
      window?.mobile?.exceuteDeeplink(JSON.stringify(url));

      break;

    case SOURCE_WEBVIEW:

      makeApiCal(action);
      window?.webkit?.messageHandlers?.onDeeplinkExecuted?.postMessage(
        `${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
      );

      // window?.mobile?.onDeeplinkExecuted(
      //   `${url?.screen},${url?.paramOne},${url?.paramTwo},${url?.paramThree}`
      // );

      window?.mobile?.onDeeplinkExecutedV2(JSON.stringify(url));

      break;
    default:
      return;
  }
};