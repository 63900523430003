import React from "react";

const setBodyOverflow = (overflow) => {
    document.body.style.overflow = overflow;
}

const useBodyOverflowHidden = () => {
    React.useEffect(() => {
        // window.scrollTo(0, 0);
        setBodyOverflow('hidden');
        return () => setBodyOverflow('unset');
    })
}

export default useBodyOverflowHidden;