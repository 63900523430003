import React from 'react';
import MilestoneCard from './MilestoneCard';
import RevenueIcon from '../../Resources/images/CPL/revenueIcon.png';
import BackgroundTools from '../../Resources/images/CPL/backgroundTools.png';
function Checkpoints({ data, revenueData }) {
  console.log(revenueData);
  return (
    <div className="MilestoneCheckpoint">
      <div className="MilestoneCheckpoint__trophy">
        <img
          style={{
            width: '100%',
          }}
          src={revenueData?.trophyImage}
        />
      </div>
      <div className="MilestoneCheckpoint__revenueHolder">
        {/* <img src={BackgroundTools}
        style={{
          width:"20px",
        }}/> */}
        <div className="MilestoneCheckpoint__revenueHolder-icon">
          <img src={RevenueIcon} />
        </div>
        <div>
          <div className="MilestoneCheckpoint__revenueHolder-heading">
            Today's Revenue
          </div>
          <div className="MilestoneCheckpoint__revenueHolder-revenue">
            ₹ {revenueData.gmv.toLocaleString()}
          </div>
        </div>
      </div>
      <div className="MilestoneCheckpoint__Top">
        <div className="MilestoneCheckpoint__Top--Heading">
          {data && data.heading}
        </div>
        <div className="MilestoneCheckpoint__Top--SubHeading">
          {data && data.subHeading}
        </div>
      </div>
      <div className="Milestone__Body">
        {data?.mileStoneData?.map((award, index) => (
          <MilestoneAward
            key={award.amount}
            barColor={award.barColor}
            dotColor={award.dotColor}
            icon={award.icon}
            amount={award.heading}
            amountColor={award.textColor}
            emblem={award.emblem}
            awardImg={award.imgUrl}
            isComplete={award.isComplete}
            isWinning={award.isWinning}
            index={index}
            percentageCompleted={award.percentage}
          />
        ))}
      </div>

      {/*<div className="MilestoneCheckpoint__List">
        {data &&
          data.mileStoneData &&
          data.mileStoneData.map((data, i) => {
            return <MilestoneCard key={i} index={i} data={data} />;
          })}
        </div>*/}
    </div>
  );
}

const MilestoneAward = ({
  barColor,
  dotColor,
  icon,
  amount,
  amountColor,
  emblem,
  awardImg,
  isComplete,
  isWinning,
  index,
  completedIcon,
  percentageCompleted
}) => {
  return (
    <div className="milestoneAward">
      <div
        className="milestoneAward__progress"
        style={{ background: barColor }}
      >
        {icon ? (
          <img src={icon} alt="award-icon"/>
        ) : (
          <div
            className="milestoneAward__progress__dot"
            style={{
              background: dotColor,
            }}
          ></div>
        )}
      </div>
      <div
        className="milestoneAward__reward"
        style={{
          border: isWinning ? '1px solid #F2B029' : 'none',
          background: isComplete && !isWinning ? '#54881C' : '#207241',
        }}
      >
        <div
          className={
            isComplete && !isWinning
              ? 'milestoneAward__reward__score__completed'
              : 'milestoneAward__reward__score'
          }
        >
          {console.log('>>>>test', index, isWinning, isComplete)}
          <p
            className="milestoneAward__reward__score__p1"
            style={{
              color: !isWinning && isComplete ? '#FFFFFF' : '#FFFFFF', //isComplete&& !isWinning?"#FFFFFF":"rgba(0, 0, 0, 0.87)",
              padding: isComplete && !isWinning ? '6px 11px' : '0px 11px',
              fontSize: isComplete && !isWinning ? '20px' : '24px',
            }}
          >
            ₹ {amount}
          </p>
          {emblem.text && (
            <p
              className="milestoneAward__reward__score__p2"
              style={{
                // background: emblem?.bgColor,
                color: emblem?.color, //"rgba(255, 255, 255, 1)",
                fontSize: isWinning ? '10px' : '16px',
                marginRight: isWinning && isComplete ? '6px' : '',
              }}
            >
              {emblem?.text}
            </p>
          )}
        </div>
        {isComplete && !isWinning ? (
          ''
        ) : (
          <div className="milestoneAward__reward__image milestoneAward__reward__image">
            <div>
              {console.log(emblem?.text,"percentage")}
              <div style={{
                position: "absolute",
                right: "0",
                top: "0px",
                zIndex: "1000",
                backgroundColor: "grey",
                padding: "5px",
                color: "#FFFFFF",
                fontWeight: "bold",
                width: isComplete ? "0%" : `${100 - percentageCompleted}%`,
                height: "100%",
                opacity: "0.6",
                borderTopLeftRadius: '9px',
                borderBottomLeftRadius: '9px',
              }}></div>
            <img
              src={awardImg}
              alt="rewardImg"
              className={isWinning ? 'isWinning' : null}
            />
            {isComplete && !isWinning ? (
              <div className="milestoneAward__reward__image__overlay"></div>
            ) : null}
          </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Checkpoints;
