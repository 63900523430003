import React from 'react';
import './progressMeter.scss';
import QualifierFlagIcon from "../../../Resources/images/IPL/qualifierFlagIcon.png"

const dividerColorChange = [5,15,25,35,46,56,66,76,87,97]

function ProgressMeter(props) {
  const { width, mileStoneData,data,isQualified } = props;
  console.log(width)
  let progressBarMargin = isQualified?{"margin-top": "60px"}:{}
  return (
    <div className="progressBarContainer" style={progressBarMargin}>
      <div className="progressBarContainer__ProgressBar">
        <span
          className="progressBarContainer__ProgressBar__Indicator"
          style={{ width: `${width}%` }}
        >
          <span className="progressBarContainer__ProgressBar__Indicator--Fill"></span>
        </span>
       {/* { !isQualified&&<><div className="progressBarContainer__qualifierBar"/>
      <img src={QualifierFlagIcon} className="progressBarContainer__qualifierIcon"/></>} */}
      </div>
      {/* <div className="progressBarContainer__progressBarDividersContainer"> */}
        {/* <><div className="progressBarContainer__progressBarDividersContainer__completion">
          {data?.completedPercent}
        </div>
          <div className="progressBarContainer__progressBarDividersContainer__goal">
            Goal:{' '} {data?.goal}
          </div></> */}
        {<div className="progressBarContainer__progressBarDividersContainer__row">
          {mileStoneData?.map((milestone, index) => <ProgressBarDividers key={index} index={index} amount={milestone?.text} bgColor={milestone?.bgColor} textColor={milestone?.textColor} width={width} />)}
        </div>}
      {/* </div> */}
    </div>
  );
}

const ProgressBarDividers = ({ index, amount, bgColor, textColor, width=0 }) => {
  return (
    <div className="progressBarDividers" style={{ marginTop: index % 2 !== 0 ? "19.5px" : "-18px" }}>
      {index % 2 === 0 && <div className="progressBarDividers__priceEmblem progressBarDividers__priceEmblem--top" style={{
        background: bgColor,
        color: textColor,
        "--databgColor": bgColor,
        marginBottom:"11px",
      }}>{amount}</div>}
      <div className="progressBarDividers__progressBarDividerLines"
      style={{background:width>=dividerColorChange[index]?"#FFFFFF":"#CFCFCF"}}></div>
      {index % 2 !== 0 && <div className="progressBarDividers__priceEmblem progressBarDividers__priceEmblem--bottom" style={{
        background: bgColor,
        color: textColor,
        "--databgColor": bgColor,
        marginTop: "11px",
      }}>{amount}</div>}
    </div >
  )
}

export default ProgressMeter;
