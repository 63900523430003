import React from "react";
import ProgressBar from "../UI/ProgressBar/ProgressBar";
import trophy from "../../Resources/images/TrophyThumb.svg";
import holiColor from "../../Resources/images/holiColor.png";
import Coin from "../../Resources/images/Coin.svg";
import DrawerTopIcon from "../../Resources/images/IPL/drawerTopIcon.png";

function Drawer(props) {
  const { data } = props;
  // const DrawerTopIcon = {
  //   right: "10%",
  //   top: "0%",
  //   width: "13rem",
  // }
  console.log(">>>>Data", data.percentage);
  console.log("dd",data?.homeMileStone?.nextMilestone?.nextMilestone)

  return (
    <div
      className="Drawer"
      // style={{ borderColor: data?.bgColor, backgroundColor: data?.bgColor }}
    >
      <div className="Drawer__Top">
        <div className="Drawer__Top__Profile">
          <img
            className="Drawer__Top__Profile--Img"
            src={
              data?.logo ||
              "https://whitelabel-assets.s3.ap-south-1.amazonaws.com/production/campaign/Icons/defaultLogo.svg"
            }
            alt="LOGO"
          />
          <span style={{}} className="Drawer__Top__Profile--Name">
            {" "}
            {data?.orgName}
          </span>
        </div>

        {/* <div>
          <img
            src={DrawerTopIcon}
            style={{
              right: '-90px',
              top: '-10px',
              width: '15rem',
              position: 'absolute',
            }}
          />
        </div> */}
        {/* <div className="Drawer__Top__Points">
          <span className="Drawer__Top__Points--Count">{data?.points} pts</span>
          <img src={Coin} alt="o" />
        </div> */}
      </div>
      <div className="Drawer__Body">
        <div className="Drawer__Body__Stats">
          <div className="Drawer__Body__Stats--Earning">{data?.earnedGmv}</div>
          <div className="Drawer__Body__Stats--Goal">
            {!data?.homeMileStone?.nextMilestone?.isComplete ? (
              <div style={{ display: "flex", gap: "3px", fontSize: "17px" }}>
                <p>Next</p>
                <p style={{marginRight:"4px"}}>Milestone: </p>
              </div>
            ) : ("")}
            <div
              className="Drawer__Body__Stats--Goal--value"
              style={{
                width: "80%",
                fontSize: "1.2rem",
                marginLeft:"auto",
              }}
            >
              {data?.homeMileStone?.nextMilestone?.nextMilestone}
            </div>
          </div>
        </div>

        <ProgressBar
          background={
            // data?.campaignOrg?.progressBarColor
            //  `#582363`
            "linear-gradient(270deg, #FEC84F 0%, #F0B441 100%)"
          }
          width={data?.percentage}
        />
      </div>
      {/*<div className="Drawer__Button" onClick={data?.hanleClick}>
            <img src={trophy} alt="" />
            <span className="Drawer__Button--Text">Your Rewards</span>
      </div>*/}
    </div>
  );
}

export default Drawer;
