import React from 'react';
import trophy from '../../Resources/images/TrophyThumb.svg';
import Coin from '../../Resources/images/Coin.svg';

function DrawerShimmer(props) {
  const { data } = props;
  return (
    <div
      className="Drawer"
      style={{ borderColor: data?.bgColor, backgroundColor: data?.bgColor }}
    >
      <div className="Drawer__Top">
        <div className="Drawer__Top__Profile">
          <div className="Drawer__Top__Profile--Img shine" />

          <span className="Drawer__Top__Profile--Name">
            {' '}
            <div className="Drawer__Top__Profile--Name--Shimmer shine" />
          </span>
        </div>
        <div className="Drawer__Top__Points">
          <span className="Drawer__Top__Points--Count">
            <div className="Drawer__Top__Points--Count--Shimmer shine" />{' '}
          </span>
          <img src={Coin} alt="o" />
        </div>
      </div>
      <div className="Drawer__Body">
        <div className="Drawer__Body__Stats">
          <div className="Drawer__Body__Stats--Earning">
            <div className="Drawer__Body__Stats--Earning--Shimmer shine" />
          </div>

          <div className="ProgressBar shine">
            <span className="ProgressBar__Indicator">
              <span className="ProgressBar__Indicator--Fill"></span>
            </span>
          </div>

          <div className="Drawer__Body__Stats--Goal">
            Goal: <div className="Drawer__Body__Stats--Goal--Shimmer shine" />
          </div>
        </div>
      </div>
      {/*<div className="Drawer__Button" onClick={data?.hanleClick}>
        <img src={trophy} alt="" />
        <span className="Drawer__Button--Text">Your Rewards</span>
  </div>*/}
    </div>
  );
}

export default DrawerShimmer;
