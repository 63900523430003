import React from "react";
import "./RevenueCard.scss";

import ProgressMeter from "../../UI/progressMeter/progressMeter"; //'../../UI/ProgressBar/ProgressBar';
import totalPointCoin from "../../../Resources/images/CPL/totalPointCoin.png";
import yourRankTrophy from "../../../Resources/images/CPL/yourRankTrophy.png";
import Coin from "../../../Resources/images/Coin.svg";
import json from "../../../Resources/lf20_pbkd6rqx.json";
import ProgressBar from "../../UI/ProgressBar/ProgressBar";

function RevenueCard(props) {
  const { data, changeRoute, handleClick, isQualified, time } = props;
  const isQualifiedFooter = isQualified ? { marginTop: "43px" } : {};
  // console.log(data, '???Data');
  return (
    <div className="RevenueCard" onClick={handleClick}>
      {/* <lottie-player
          src = {"https://cp-assets-web.s3.ap-south-1.amazonaws.com/campaignJSON/lf20_pbkd6rqx.json"}
          // src={json}
          background="transparent"
          speed="1"
          // style={{ width: "100%", height: "43%" }}
          loop
          autoplay
          /> */}
      <div
        className="RevenueCard__Top"
        style={{ backgroundImage: `url(${data?.cardBgImage})` }}
      >
        <div className="RevenueCard__Top--container">
          <div className="RevenueCard__Top--leftLabel"></div>
          <div>
            <div className="RevenueCard__Top--Label">
              {data?.revenue?.title}
            </div>
            <div className="revenueCard__Head">
              <div className="RevenueCard__Head--Logo">
                <div className="RevenueCard__Head--Logo--Text">
                  {data?.revenue.value}
                </div>
              </div>
            </div>
          </div>
        </div>
        <ProgressMeter
          width={data?.nextMilestone?.percentage} //data?.percentage
          mileStoneData={data?.nextMilestone?.mileStoneData}
          bgColor={data?.progressBarColor}
          thick={20}
          isQualified={isQualified}
          data={isQualified ? data?.revenue : data?.revenueCard}
        />

        {/* <ProgressBar
          width={data?.revenueCard?.percentage}
          background={'#F0B441'}
          thick={16}
        /> */}
        {/* <div
          style={{ marginBottom: '10px' }}
          className="RevenueCard__New_Footer--contianer"
        > */}
        {/* <div className="RevenueCard__New_Footer__right">
            {data?.revenueCard?.completedPercent}
          </div> */}
        {/* <div className="RevenueCard__New_Footer__right">
            Goal: {data?.revenueCard?.goal}
          </div>
        </div> */}
        {/* <div className="RevenueCard__New_Footer--contianer"> */}
        {/* <div className="RevenueCard__New_Footer--Rank">
            <div>
              <div className="RevenueCard__New_Footer--Rank--heading">
                {data?.rankCard?.text}
              </div>
              <div className="RevenueCard__New_Footer--Rank--value">
                <img src={data?.rankCard?.icon} alt="i" />
                <span>{data?.rankCard?.title}</span>
              </div>
            </div> */}
        {/* <div className="RevenueCard__New_Footer--Rank--imgContainer"> 
            <img
              className="RevenueCard__New_Footer--Rank--imgContainer-img"
              src={yourRankTrophy}
              width={64}
            /> */}
        {/* </div> 
          </div>
          <div className="RevenueCard__New_Footer--points">
            <div>
              <div className="RevenueCard__New_Footer--points--heading">
                {data?.pointsCard?.text}
              </div>
              <div className="RevenueCard__New_Footer--points--value">
                <img src={data?.pointsCard?.icon} alt="i" />
                <span> {data?.pointsCard?.title}</span>
              </div>
            </div> */}
        {/* <div className="RevenueCard__New_Footer--points--imgContainer"> 
            <img
              className="RevenueCard__New_Footer--points--imgContainer-img"
              src={totalPointCoin}
              width={64}
            /> */}
        {/* </div> 
          </div>
        </div> */}
        <div className="RevenueCard__New_Footer" style={isQualifiedFooter}>
          {/* <div className="RevenueCard__New_Footer--Rank">
            <div>
              <div className="RevenueCard__New_Footer--Rank--heading">
                {data?.rankCard?.text}
              </div>
              <div className="RevenueCard__New_Footer--Rank--value">
                <img src={data?.rankCard?.icon} alt="i" />
                <span>{data?.rankCard?.title}</span>
              </div>
            </div>
            <div className="RevenueCard__New_Footer--Rank--imgContainer">
              <img className="RevenueCard__New_Footer--Rank--imgContainer-img" src={yourRankTrophy} width={64} />
            </div>
          </div>
          <div className="RevenueCard__New_Footer--points">
            <div>
              <div className="RevenueCard__New_Footer--points--heading">
                {data?.pointsCard?.text}
              </div>
              <div className="RevenueCard__New_Footer--points--value">
                <img src={data?.pointsCard?.icon} alt="i" />
                <span> {data?.pointsCard?.title}</span>
              </div>
            </div>
            <div className="RevenueCard__New_Footer--points--imgContainer">
              <img className="RevenueCard__New_Footer--points--imgContainer-img" src={totalPointCoin} width={64} />
            </div>
          </div> */}
          <div
            className="RevenueCard__New_Footer__left"
            style={{ width: "100%" }}
          >
            {!data?.nextMilestone?.isComplete && (
              <span className="RevenueCard__New_Footer__left--heading">
                Next Milestone :
              </span>
            )}
            <span
              style={{
                fontSize: data?.nextMilestone?.isComplete ? "3.2vw" : "0 6px",
                margin: data?.nextMilestone?.isComplete ? "auto" : "0 6px",
              }}
              className="RevenueCard__New_Footer__left__mileStoneValue"
            >
              {data?.nextMilestone?.nextMilestone}
            </span>
          </div>
          {/* <div className="RevenueCard__New_Footer__left"
            style={{width:"100%"}}>
            {!data?.nextMilestone?.isComplete&&<span className="RevenueCard__New_Footer__left--heading">Next Milestone :</span>}<span 
            style={{
              fontSize:data?.nextMilestone?.isComplete?"3.2vw":"0 6px",
              margin: data?.nextMilestone?.isComplete?"auto":"0 6px"
          }}
            className="RevenueCard__New_Footer__left__mileStoneValue">
              {data?.nextMilestone?.nextMilestone}</span>
          </div> */}
          {/* <div className="RevenueCard__timeLine shine">
            <div className="RevenueCard__timeLine__text">
              {data?.text}
            </div>
            <div className="RevenueCard__timeLine__ending">
              <div className="RevenueCard__timeLine__ending-text">
                CAMPAIGN ENDING IN:
                </div>
              <div className="RevenueCard__timeLine__ending-time">
                {time}
              </div>
            </div>
          </div> */}

          {/* <div className="RevenueCard__New_Footer__right">
            Goal: {data?.goal}
          </div> */}
        </div>
      </div>
    </div>
  );
}

export default RevenueCard;
