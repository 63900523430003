import React from 'react';
import './growth.scss';

import { playLists } from './Content/insightData';
import InsightCard from './Content';
import PrizeHeader from '../UI/Header';

function GrowthInsights(props) {
  return (
    <div className="GrowthInsights">
      <div className="GrowthInsights__Top">
        <div className="GrowthInsights__Top--Heading">Store Academy</div>
        <div className="GrowthInsights__Top--SubHeading">
          Learn how you can grow your <br />
          business & teach more students <br />
          with courses on store
        </div>
      </div>

      <div className="GrowthInsights__Content">
        {playLists?.map((data, i) => {
          return <InsightCard data={data} key={i} />;
        })}
      </div>
    </div>
  );
}

export default GrowthInsights;
