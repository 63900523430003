import React from 'react';
import { useHistory } from 'react-router-dom';

import LPText from '../Rewards/Assets/LPText';

import Trophy from '../../Resources/images/trophy.svg';

function NoRewards(props) {
  const history = useHistory();

  return (
    <div className="RewardsPage__Unclaimed">
      <div className="RewardsPage__Unclaimed__Trophy">
        <img src={Trophy} />
        <div className="RewardsPage__Unclaimed__Trophy__text">
          You have no rewards yet
        </div>
      </div>
      <LPText showHeading={false} />
      <button
        className="RewardsPage__Unclaimed__button"
        onClick={() => history.push('/details/rewards')}
      >
        Claim Guru Dakshina
      </button>
    </div>
  );
}

export default NoRewards;
