import React from "react";
import "./PeakAndStatsCard.scss";
import mainCardHeaderImg from "../../../Resources/images/baisakhi/mainCardHeaderImg.svg";
import homeSlideleftBg from "../../../Resources/images/beatHeat/homeSlideleftBg.png"
import remainingSlideLeftBg from "../../../Resources/images/beatHeat/remainingSlideLeftBg.png"

const PeaknStatsCard = (props) => {
  const { text, data, peakLeftCard = {}, peakLeftCardImg, isHomeSlide = false } = props;
  // console.log(props);
  const getLeftcardStyle = () => {
    if (data?.text == "Camp0aign Revenue") {
      return {
        paddingTop: "15px",
        ...peakLeftCard,
      };
    }
    return {
      ...peakLeftCard,
    };
  };
  return (
    <>
      <div
        className="PeaknStatsCard"
        style={{
          gridTemplateColumns: data?.gridTemplateColumns || "1fr 1fr",

          // ...props.otherBannerStyle,
        }}
      >
        <div style={props.style}className="  testing__text">

          {text}
        </div>
        {/* <div className="PeaknStatsCard_left" style={getLeftcardStyle()}>
        {props.leftImage?<img style={{...peakLeftCardImg}} src={props.leftImage} alt="" />:
        data.text}
      </div> */}

        <div className="PeaknStatsCard_right">
          {/* TODO LOTTIE PLAYER */}
          {/* <lottie-player
          src = {"https://assets2.lottiefiles.com/packages/lf20_czftnev1.json"}
          // src={json}
          background="transparent"
          speed="1"
          style={{ width: "250%", height: "186%", marginRight: `${props.margin ?  props.margin + 'px' : '-60px'}`, marginTop:'-35%', position: 'absolute'  }}
          loop
          autoplay
          /> */}
          {props.image && <img src={props.image} alt="gift" loading="eager"
            style={{ position: "absolute", ...props.imagePostion }} />}
        </div>
        {props.children}
      </div>
      {/* <hr style={{
        margin: "0 20px",
        marginTop: "-40px",
        backgroundColor: "#FFE3A6",

      }}>
      </hr> */}
    </>
  );
};

export default PeaknStatsCard;
