import React from "react";

import "./tools.scss";

import ToolCard from "../../Tools/ToolCard";

function ToolsSection(props) {
  const { data } = props;

  return (
    <div className="ToolsSection">
      {data?.map((data, i) => {
        return (
          <ToolCard
            data={data}
            key={i}
            style={{
              display: "flex",
              flexFlow: "row nowrap",
              flexDirection: "row",
              /* align-items: center; */
              gap: "8px",
            }}
          />
        );
      })}
    </div>
  );
}

export default ToolsSection;
