import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import holiRewardImage from "../../../Resources/images/rewardIllustration.png"
import BannerImage from '../../../Resources/images/rewards.png';
import CardFooter from '../CardFooter/CardFooter';
import { directDeepLink, SOURCE_HOME } from '../../../utils';
import rewardsCardHeaderImg from "../../../Resources/images/baisakhi/RewardsText.svg";
import RewardsText from "../../../Resources/images/south/Rewards.svg";
import SingleRibbon from "../../../Resources/images/baisakhi/SingleRibbon.svg";
import Mogu from "../../../Resources/images/baisakhi/MoguDesign.svg";
import RewardImg from "../../../Resources/images/CPL/rewardImg.png"
import RewardSlideLeftImg from "../../../Resources/images/beatHeat/rewardSlideLeftImg.png"
import RewardSlideTopBg from "../../../Resources/images/carnival/rewardSlideTopBg.png";
import BgRainCard from "../../../Resources/images/rewardBgSide.png"
import CheckListSlideImg from "../../../Resources/images/carnival/checkListSlideImg.png"
import lights from './lights.gif'
import { useHistory } from "react-router-dom"

const lottieStyle = {
  position: "absolute",

  height: "130%",
  right: '-17%',
  bottom: "0px",
  width: "68%",
};
const lottieStyleSouth = {
  position: "absolute",
  right: '-17%',
  bottom: '-37%',
  width: '69%',
};
const lottie = (isNorth) => {
  return (
    <div style={isNorth ? lottieStyle : lottieStyleSouth}>
      <lottie-player
        src={
          isNorth
            ? "https://assets6.lottiefiles.com/packages/lf20_kwvhibnl.json"
            : "https://assets3.lottiefiles.com/packages/lf20_IaX52r.json"
        }
        background="transparent"
        speed="1"
        style={{ width: "100%", height: "100%" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};
function RewardSlide(props) {
  const { data, isNorth } = props;
  const history = useHistory();

  const imagePostion = {
    right: "4%",
    top: "0%",
    width: "16rem",
  }

  const customStyle = {
    paddingTop: "14px"
  }

  const footerTextStyle = {
    marginBottom: "21px"
  }

  const otherBannerStyle = {
    background: `url(${RewardSlideTopBg}) no-repeat`,
    backgroundPosition: "-5px -5px",
    backgroundSize: "103% 100% !important",
    // paddingTop: "1.4rem"
  }
  const banner = {
    text: '',//'Win Big this summer season',
    image: BannerImage,
    gridTemplateColumns: "1.4fr 1fr",
  };

  const footer = {
    text: data?.subText,
    button: {
      text: 'View Milestones & Rewards',
      bgColor: '#F78C64',
    },
    deepLink: data?.deeplink,
    onClickEvent: () => { }
  };

  return (
    <div
      className="Slide"
      style={{

        backgroundImage: `url(${BgRainCard})`,
        // backgroundSize: "cover",
        backgroundSize: '100% 100%',
        backgroundPosition: "center",
        backgroundColor: "",
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: "8px",
        margin: "2px",
        // border: '2px solid rgb(253, 202, 0)'
      }}
    >
      {/* <img
        src={lights}
        width="100%"
        style={{
          width: '167%',
          marginLeft: '65%',
        }}
      /> */}
      <PeaknStatsCard
      style={{
				fontSize: "3rem",
				marginTop: "25px",
				marginLeft: "18px",
				textAlign: "initial",
				width: "94%",
				lineHeight: "26px",
				color:"white"
			  }}
        data={banner}
        // image={holiRewardImage}
        // backgroundImage={RewardSlideLeftImg}
        otherBannerStyle={otherBannerStyle}
        // topRightImg={SingleRibbon}
        // image = {RewardSlideImg}
        text="Win big this Summer Season"
        // image={CheckListSlideImg}
        rightBkgImg={isNorth ? Mogu : ''}
        imagePostion={imagePostion}
        margin={-110}
      >
        <span
					style={{
						fontSize: '14px',
						marginLeft: '23px',
						marginTop: '-12px',
						color: 'white',
            fontFamily:'Open Sauce One',
            fontStyle:'normal',
            fontWeight:'600',
					}}
				>
					#youmakethefuture
				</span>
        {/* {lottie(isNorth)} */}
      </PeaknStatsCard>
      <div
        style={{
          // borderRadius: "4px",
          // background: "#FFFFFF",
          height: "100%",
        }}
      >
        <div className="Slide__Rewards">
          <img
            style={{ width: "31rem" }}
            onClick={() => directDeepLink(data?.deeplink, SOURCE_HOME)}
            src={data?.imgUrl}
            alt="Placeholder for icon which gives glimpse of rewards upfront"
          />
          <div className="Slide__Rewards__Text">
            <div className="Slide__Rewards__Text--Impact">
              {data?.rewardsText}
            </div>
            <div className="Slide__Rewards__Text--Count">{data?.BoldText}</div>
          </div>
        </div>
        <CardFooter
          data={footer}
          styleFooter={customStyle}
          footerTextStyle={footerTextStyle}
        />
      </div>
    </div>
  );
}

export default RewardSlide;
