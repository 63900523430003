import React from 'react';
import MilestoneCard from './MilestoneCard';
import { directDeepLink, SOURCE_HOME } from '../../../utils';
import './milestone.scss';
import MilestoneMark from './MilestoneMark';

function MilestoneSection(props) {
  const { data, deepLink, textData } = props;
  const visibleData = data?.slice(0,6)
  return (
   <div className="MilestoneSectionContainer">
     <div className="MilestoneHeaderText">
       {textData?.text}<span className="MilestoneHeaderText__amount">{textData?.amount}</span>{textData?.text2}
     </div>
    <div
      className="MilestoneSection"
      style={{
        gridTemplateColumns: data?.length === 4 ? '1fr 1fr' : '1fr 1fr 1fr',
      }}
      // onClick={directDeepLink(deepLink, SOURCE_HOME)}
    >
      {/* {(visibleData.length)>4&& <div className="MilestoneSection__Road">
        {visibleData?.map((item, i) => (
          <div
            className="MilestoneSection__Road--Block"
            key={i}
            style={
              i % 2 === 0
                ? {
                    borderRadius: '0 57px 57px 0',
                    borderLeft: 'none',
                    marginLeft: i === 0 ? '24px' : '54px',
                    width: i === 0 ? '115%' : '100%',
                  }
                : {
                    borderRadius: ' 57px 0 0 57px ',
                    borderRight: 'none',
                    marginLeft: '-54px',
                  }
            }
          ></div>
        ))}
      </div>} */}
      {visibleData?.map((el, i) => {
        return <MilestoneMark amount={el.amount} image={el.img} />;
      })}
    </div>
    </div>
  );
}

export default MilestoneSection;
