import "./CircularPercentage.scss"

export default function CircularPercentage(props = {}) {
    return <div className="CircularPercentageContainer"
        style={{
            width: `${props.width || 36}px`,
            height: `${props.width || 36}px`
        }}
    >
        <span
            style={{
                fontSize: `${props.fontSize || 8}px`,
                top: "39%",//`${props.fontPositionTop || 25}%`,
                left: "50%",//`${props.fontPositionLeft || 21}%`,
                color: "white",
                // color: `${props.fontColor || "#FFFFFF"}`,
                transform: "translate(-50%, -50%)",
            }}
            className="CircularPercentageText">{props.percentage || 0}%</span>
        <div style={{
            display: "flex",
            justifyContent: "center",
            // alignItems:"center",
            height: "100%",
            width: "100%"
        }}
        ><svg viewBox={`0 0 ${props.width || 36} ${props.width || 36}`}
            class="circular-chart">
                <path
                    style={{
                        "stroke-width": `${props.strokeWidth || 4}px`
                    }}
                    class="circle-bg"
                    d="M18 2.0845
          a 15.9155 15.9155 0 0 1 0 31.831
          a 15.9155 15.9155 0 0 1 0 -31.831"
                />
                <path class="circle"
                    style={{
                        "stroke": props.strokeColour || "#4CC790",
                        "stroke-width": `${props.strokeWidth || 4}px`
                    }}
                    stroke-dasharray={`${props.percentage || 0}, 100`}
                    d="M18 2.0845
      a 15.9155 15.9155 0 0 1 0 31.831
      a 15.9155 15.9155 0 0 1 0 -31.831"
                />
            </svg></div>
    </div>
}

