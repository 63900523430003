import React from 'react';
import back from '../../../Resources/images/back.svg';
import './Header.scss';
import { useHistory } from 'react-router-dom';

function Header(props) {
  const history = useHistory();
  const { heading, route } = props;
  return (
    <div className="Header">
      <img
        loading="lazy"
        src={back}
        alt=""
        width="12px"
        style={{ marginRight: '28px' }}
        onClick={() => history.goBack()}
      />
      {heading}
    </div>
  );
}

export default Header;
