import React from 'react';
import './loader.css';

function ContentLoader() {
  return (
    <div className="loaderWrapper">
      <div className="lds-ellipsis">
        <div />
        <div />
        <div />
        <div />
      </div>
    </div>
  );
}

export default ContentLoader;
