import React from 'react';
import RewardCard from '../Rewards/RewardCard/RewardCard';

function ClaimedRewards(props) {
  const { data } = props;
  return (
    <div className="RewardsPage__Claimed">
      <div className="RewardsPage__Claimed__Heading">Claimed Rewards</div>
      <div className="RewardsPage__Claimed__CardsContainer">
        {data?.map((reward, i) => (
          <RewardCard key={`reaward${i}`} value={reward} />
        ))}
      </div>
    </div>
  );
}

export default ClaimedRewards;
