import React from 'react';

function MilestoneMark({ amount, image }) {
  const colorSet = ['#5993EE', '#925BB5', '#08AACE', '#EE7459', '#5BB5B5'];

  return (
    <div className="MilestoneMark">
      {/* <div className="MilestoneMark--Dot"></div> */}
      <img
        src={image}
        alt=""
        width={57}
        height={48}
        className="MilestoneMark--Award"
        style={{
          // backgroundColor:
          //   colorSet[Math.floor(Math.random() * colorSet?.length)],
        }}
      />
      <div className="MilestoneMark--Amount">{amount}</div>
    </div>
  );
}

export default MilestoneMark;
