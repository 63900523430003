import React, { useState, useEffect } from 'react';

import LightsImage from '../../../Resources/images/Winter.png';
import PeaknStatsCard from '../PeakAndStatsCard';
import RevenueCard from '../RevenueCard';
import CardFooter from '../CardFooter/CardFooter';
import HomeSlideImg from '../../../Resources/images/carnival/homeSlideImg.png';
import homeSlideLeftImg from '../../../Resources/images/carnival/homeSlideLeftImg.png';
import homeSlideTopBg from '../../../Resources/images/carnival/homeSlideTopBg.png';
import { Fragment } from 'react/cjs/react.production.min';
import BgRainCard from '../../../Resources/images/BgRainCard.png';
import CheckListSlideImg from '../../../Resources/images/carnival/homeRightSlide.png';
import Mogu from '../../../Resources/images/baisakhi/MoguDesign.svg';
import HomeSlideLeftImg from "../../../Resources/images/beatHeat/homeSlideLeftImg.png"
import navratriImage from "../../../Resources/images/carnival/navratri.png";
import { webEngageSaleServiceRevamp } from '../../../webEngage/service';
import lights from './lights.gif'
import * as webEvents from "../../../webEngage/event"
import { useHistory } from "react-router-dom"
function HomeSlide(props) {
  const { data, campaignOrg, isNorth, isQualified } = props;
  const history = useHistory();
  const [time, setTime] = useState(null);
  const [timeStamp, setTimeStamp] = useState(data?.campaignEndTime);
  useEffect(() => {
    const dayBase = 60 * 60 * 24;
    const hourBase = 60 * 60;
    const minuteBase = 60;
    const days = Math.floor(timeStamp / dayBase),
      hours = Math.floor((timeStamp % dayBase) / hourBase),
      minutes = Math.floor((timeStamp % hourBase) / minuteBase),
      seconds = timeStamp % minuteBase;
    // if (increment) {
    setTime(
      `${days ? `${days}D` : ''} ${hours ? `${hours}H` : ''} ${minutes ? `${minutes}M` : ''
      }`
    );
    setTimeout(() => {
      setTimeStamp((prevData) => prevData - 60);
    }, 60000);
    // }
  }, [timeStamp]);

  const banner = {
    text: 'Summer Super Saver Sale', //campaignOrg.title || "",
    image: LightsImage,
    gridTemplateColumns: '2fr 1fr',
  };
  let imagePostion = {
    right: '0%',
    top: '-11%',
    width: '15rem',
    marginRight: "2%",
  };


  // if(isQualified){
  //   imagePostion.width = "180px"
  //   imagePostion.bottom = "-91px"
  // }

  const peakLeftCardImg = {
    width: "100px",
    marginRight: "25%",
  }
  const otherBannerStyle = {
    background: `url(${homeSlideTopBg}) no-repeat`,
    backgroundPosition: '-5px -5px',
    backgroundSize: '103% 100% !important',
  };
  const footer = {
    text: data?.activeOrgs,
    button: {
      text: 'View Milestones & Rewards',
      bgColor: '#F78C64',
    },
    deepLink: data?.deeplink,
    clickEvent: () => { webEngageSaleServiceRevamp(webEvents.SALE_VIEW_LEADERBOARD_CLICK, {}) }
  };

  const customStyle = {
    padding: '10px 20px 12px 20px',
  };
  const lottieStyle = {
    position: 'absolute',
    backgroundColor: '#2B1451',
    height: '100%',
    paddingTop: '6%',
    display: 'flex',
    alignItems: 'flex-end',
  };

  const lottieStyleSouth = {
    position: 'absolute',
    backgroundColor: '#2B1451',
    width: '54%',
    right: '0',
    bottom: '0',
  };
  const points = [data?.rankCard, data?.pointsCard];
  const lottie = (isNorth) => {
    return (
      <div style={isNorth ? lottieStyle : lottieStyleSouth}>
        <lottie-player
          src={
            "https://assets2.lottiefiles.com/packages/lf20_czftnev1.json"
          }
          background="rgba(0, 0, 0, 0)"
          speed="1"
          style={{ width: "250%", height: "186%", marginRight: '-53px', marginTop: '-37%', position: 'absolute' }}
          // style={{ width: "100%", height: "43%" }}
          loop
          autoplay
        ></lottie-player>
      </div>
     

    );
  };
  return (
    
  
    <div
      className="Slide"
      style={{
        backgroundImage: `url(${BgRainCard})`,
        // backgroundSize: 'cover',
        backgroundSize: '100% 100%',
        backgroundPosition: 'center',
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '3px',
        // border: '2px solid rgb(253, 202, 0)'
      }}
    >

      
      {/* <div>
       <img src={require('../../../Resources/images/carnival/GaneshImage.png').default} />
       </div> */}
      <div style={{
        display: 'flex',
        justifyContent: "center",
        alignItems: 'center',
        marginTop: "7px",
        width: '60%'
      }}>
        {/* <img src={lights} width="100%" style={{
          width: "167%",
          marginLeft: "65%",
        }} /> */}
      </div>

      


      <img src={navratriImage}
        style={{
          marginBottom: "-98px",
          marginTop: "29px",
          marginLeft: "4px",
          width :"47",
        }} />
      {/* <div style={{ transform: "scale(-1, 1)" }}>
        <lottie-player
          src="https://assets2.lottiefiles.com/packages/lf20_nzvh8g2c.json"
          background="transparent"
          speed="1"
          style={{ width: "300px", height: "300px" }}
          loop
          autoplay
        ></lottie-player>
      </div> */}
      
      
      <PeaknStatsCard
         
        otherBannerStyle={otherBannerStyle}
        data={banner}
        backgroundImage={HomeSlideLeftImg}
        imagePostion={imagePostion}
        // image={HomeSlideImg}
        // isNorth={isNorth}
        // peakLeftCardImg = {peakLeftCardImg}
        // isHomeSlide
        // otherBannerStyle={otherBannerStyle}
        // topRightImg={SingleRibbon}
        // topRightImg={SingleRibbon}
        // text="Chaturthi"
        // image={CheckListSlideImg}
        rightBkgImg={isNorth ? Mogu : ''}
         
      // imagePostion={imagePostion}
      >
         <span
					style={{
						fontSize: '14px',
						marginLeft: '18px',
						marginTop: '55px',
						color: 'white',
            fontFamily:'Open Sauce One',
            fontStyle:'normal',
            fontWeight:'600',
					}}
				>
					#youmakethefuture
				</span>
        {/* {lottie(isNorth)} */}
      </PeaknStatsCard>
      
      <div
        style={{
          // background: "#FFFFFF",
          // backgroundImage: `url(${homedetailbackground})`,
          'padding-top': '13px',
          // "background-repeat": "no-repeat"
          height: '100%',
        }}
      >
        {/* <div
       style={{
         position: "absolute",
         width: "322px",
         height: "302px",
         left: "15px",
         top : "132px",
         background: " #FFFFFF",
         boxShadow : "0px 4px 4px rgba(0, 0, 0, 0.25)",
         borderRadius: "23px",
        
       }}>
       </div> */}
        <RevenueCard data={data} isQualified={isQualified} time={time} />
        {/* <PointsCard data={points} /> */}
        <CardFooter data={footer} styleFooter={customStyle} />
      </div>
    </div>
  );
}

export default HomeSlide;
