import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import homedetailbackground from "../../../Resources/images/homedetailbackground.png"
import holiTools from "../../../Resources/images/toolsIllustration.png"
// import BannerImage from '../../../Resources/images/toolsIllustration.png';
import ToolsSection from '../ToolsSection';

import ToolsBannerImg from "../../../Resources/images/CPL/toolsBannerImg.png";
import SingleRibbon from "../../../Resources/images/baisakhi/SingleRibbon.svg";
import toolsCardHeaderImg from "../../../Resources/images/baisakhi/ToolsText.svg";
import ToolsSlideLeftImg from "../../../Resources/images/beatHeat/toolsSlideLeftImg.png"
import ToolsSlideImg from "../../../Resources/images/carnival/toolsSlideImg.png"
import ToolsSlideTopBg from "../../../Resources/images/carnival/toolsSlideTopBg.png"
import { Fragment } from 'react/cjs/react.production.min';
import BgRainCard from "../../../Resources/images/toolsBgSide.png"
import CheckListSlideImg from "../../../Resources/images/carnival/checkListSlideImg.png"
import Mogu from "../../../Resources/images/baisakhi/MoguDesign.svg";
import lights from './lights.gif'

const lottieStyle = {
  position: "absolute",

  height: "100%",
  transform: 'scale(-1, 1)',
  right: '-18px',
  bottom: '-8px',
  width: '50%',
};
const lottieStyleSouth = {
  position: "absolute",

  height: "100%",

  right: '10px',
  bottom: '-8px',
  width: '37%',
};
const lottie = (isNorth) => {
  return (
    <div style={isNorth ? lottieStyle : lottieStyleSouth}>
      <lottie-player
        src={
          isNorth
            ? "https://assets6.lottiefiles.com/packages/lf20_nzvh8g2c.json"
            : "https://assets6.lottiefiles.com/packages/lf20_hzfmxrr7.json"
        }
        background="transparent"
        speed="1"
        style={{ width: "100%", height: "100%" }}
        loop
        autoplay
      ></lottie-player>
    </div>
  );
};

function ToolsSlide(props) {
  const { data, isNorth } = props;

  const banner = {
    text: '',//'Tools to increase your impact',
    // image: BannerImage,
    gridTemplateColumns: "1.3fr 1fr",
  };

  const otherBannerStyle = {
    "column-gap": "16px",
    background: `url(${ToolsSlideTopBg}) no-repeat`,
    backgroundPosition: "-5px -5px",
    backgroundSize: "103% 100% !important",
  }

  const peakLeftCard = {
    padding: "14px 0 14px 22px"
  }

  const imagePostion = {

    right: "10%",
    top: "7%",
    width: "13rem",
  }


  return (
    <div
      className="Slide"
      style={{

        backgroundImage: `url(${BgRainCard})`,
        // backgroundSize: "cover",
        backgroundSize: "100% 100%",
        backgroundPosition: "center",
        // backgroundColor: "#FFFFFF",
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: "8px",
        margin: "2px",
        // border : '2px solid rgb(253, 202, 0)'
      }}
    >

{/* <img
				src={lights}
				width="100%"
				style={{
					width: '167%',
					marginLeft: '65%',
				}}
			/> */}
      <PeaknStatsCard
      style={{
				fontSize: "3rem",
				marginTop: "33px",
				marginLeft: "16px",
				textAlign: "initial",
				width: "92%",
				lineHeight: "28px",
				color:"white"
			  }}
        data={banner}
        // image={ToolsSlideImg}
        // backgroundImage={ToolsSlideLeftImg}
        // imagePostion={imagePostion}
        // peakLeftCard={peakLeftCard}
        otherBannerStyle={otherBannerStyle}
        // topRightImg={SingleRibbon}
        // topRightImg={SingleRibbon}
        text="Tools to Boost your sales"
        //  image={CheckListSlideImg}
        rightBkgImg={isNorth ? Mogu : ''}
        imagePostion={imagePostion}
        margin={-130}
      >
        <span
					style={{
						fontSize: '14px',
						marginLeft: '23px',
						marginTop: '-22px',
						color: 'white',
            fontFamily:'Open Sauce One',
            fontStyle:'normal',
            fontWeight:'600',
					}}
				>
					#youmakethefuture
				</span>
        {/* {lottie(isNorth)} */}
      </PeaknStatsCard>
      <div
        style={{ height: "100%" }}
      >
        <ToolsSection data={data} />
      </div>
    </div>
  );
}

export default ToolsSlide;
