import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import holiRewardImage from "../../../Resources/images/rewardIllustration.png"
import BannerImage from '../../../Resources/images/rewards.png';
import CardFooter from '../CardFooter/CardFooter';
import { directDeepLink, SOURCE_HOME } from '../../../utils';
import rewardsCardHeaderImg from "../../../Resources/images/baisakhi/RewardsText.svg";
import RewardsText from "../../../Resources/images/south/Rewards.svg";
import SingleRibbon from "../../../Resources/images/baisakhi/SingleRibbon.svg";
import Mogu from "../../../Resources/images/baisakhi/MoguDesign.svg";
import RewardImg from "../../../Resources/images/CPL/rewardImg.png"
import CheckListSlideLeftImg from "../../../Resources/images/beatHeat/checkListSlideLeftImg.png"
import CheckListSlideImg from "../../../Resources/images/carnival/checkListRight.png"
import TutorOfDayBg from "../../../Resources/images/carnival/tutorOfDayBg.png"
import CheckListSlideTopBg from "../../../Resources/images/carnival/checkListSlideTopBg.png"
import BgRainCard from "../../../Resources/images/checklistBgSide.png"
import { webEngageSaleServiceRevamp } from '../../../webEngage/service';
import * as webEvents from '../../../webEngage/event';
import lights from './lights.gif'

const lottieStyle = {
  position: "absolute",

  height: "130%",
  right: '-17%',
  bottom: "0px",
  width: "68%",
};
const lottieStyleSouth = {
  position: "absolute",
  right: '-17%',
  bottom: '-37%',
  width: '69%',
};
const lottie = (isNorth) => {
  return (
    <div style={isNorth ? lottieStyle : lottieStyleSouth}>
      <lottie-player
        src={
          isNorth
            ? "https://assets6.lottiefiles.com/packages/lf20_kwvhibnl.json"
            : "https://assets3.lottiefiles.com/packages/lf20_IaX52r.json"
        }
        background="transparent"
        speed="1"
        style={{ width: "100%", height: "100%" }}
        loop
        autoplay
      ></lottie-player>

    </div>
     
    
  );
};
function CheckListSlide(props) {
  const { data, isNorth  } = props;

  const imagePostion = {
    right: "3%",
    top: "6%",
    width: "15rem",
  }

  const customStyle = {
    paddingTop: "23px"
  }

  const footerTextStyle = {
    marginBottom: "21px"
  }

  const otherBannerStyle = {
    // paddingTop: "1.4rem"
    background: `url(${CheckListSlideTopBg}) no-repeat`,
    backgroundPosition: "-5px -5px",
    backgroundSize: "103% 100% !important",
  }
  const banner = {
    text: 'Classplus Tutor of the day',
    image: BannerImage,
    gridTemplateColumns: "1.4fr 1fr",
  };

  const footer = {
    text: data?.subText,
    button: {
      text: 'View Checklist',
      bgColor: '#F78C64',
    },
    deepLink: data?.deeplink,
    clickEvent: () => webEngageSaleServiceRevamp(webEvents.SALE_VIEW_CHECKLIST_CLICK, {})
  };

  return (
    <div
      className="Slide"
      style={{

        backgroundImage: `url(${BgRainCard})`,
        // backgroundSize: "cover",
        backgroundSize :"100% 100%",
        backgroundPosition: "center",

        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: "8px",
        margin: "2px",
        // border : '2px solid rgb(253, 202, 0)'
      }}
    >
      {/* <img src={lights} width="100% " style={{
        marginTop: "1%",
         width: "167%",
         marginLeft: "50%",
      }} /> */}
      <PeaknStatsCard
      style={{
        fontSize: "3rem",
        marginTop: "33px",
        marginLeft: "8px",
        textAlign: "initial",
        width: "96%",
        lineHeight: "28px",
        color:"white"
      }}
        data={banner} 
        // image={holiRewardImage}
        // backgroundImage={CheckListSlideLeftImg}
        otherBannerStyle={otherBannerStyle}
        // topRightImg={SingleRibbon}
        text="Classplus Tutor of the day"
        // image={CheckListSlideImg}
        rightBkgImg={isNorth ? Mogu : ''}
        imagePostion={imagePostion}
        margin={-120}
        
      >
        <span
					style={{
						fontSize: '14px',
						marginLeft: '11px',
						marginTop: '-22px',
						color: 'white',
            fontFamily:'Open Sauce One',
            fontStyle:'normal',
            fontWeight:'600',
					}}
				>
					#youmakethefuture
				</span>
        {/* {lottie(isNorth)} */}
      </PeaknStatsCard>
      
      <div
        style={{
          // borderRadius: "4px",
          // background: "#FFFFFF",
          height: "100%",
        }}
      >
        <div className="Slide__CheckList"
          style={{
            marginTop: "17px",
          }}>
          <img src={TutorOfDayBg}
          style={{
            width:"32%",
            marginLeft:"4%",
          }} />
          <div className="Slide__CheckList__org" style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: "100%",


          }}>
            <img className="Slide__CheckList__orgLogo" src={data?.orgLogo}
              style={{
                margin: "0",

              }} />
            <div className="Slide__CheckList__orgName">{data?.orgName}</div>
          </div>
        </div>

        <CardFooter
          data={footer}
          styleFooter={customStyle}
          footerTextStyle={footerTextStyle}
        />
      </div>
    </div>
  );
}

export default CheckListSlide;
