import React from 'react';

import ProgressBar from '../UI/ProgressBar/ProgressBar';

import tasksuccess from "../../Resources/images/tasksuccess.png"
import Coin from '../../Resources/images/Coin1.png';
import { directDeepLink, SOURCE_WEBVIEW } from '../../utils';
import CircularPercentage from "../UI/CircularPercentage"

function TaskCard(props) {
  const { data } = props;

  return (
    <div
      className="TaskCard fadeIn"
    // style={{ backgroundColor: data?.bgColor, border: data?.border }}
    >
      <div className="TaskCard__Stats">
        <div className="TaskCard__Stats__Top">
          <div className="TaskCard__Stats__Top__Label">
            <img src={data?.icon} alt="ICON" />
            <div>
              <span className="TaskCard__Stats__Top__Label--Info">
                {data?.task}
              </span>
              {<div className="TaskCard__Stats--NotCompleted">
                <span
                  style={{
                    color: data?.subTextColor,
                    fontSize: "1.2rem"
                  }}
                >{data?.total}</span>{data?.completedText}</div>}
            </div>
          </div>
          <div>{data?.percentage == 100 ? <img src={tasksuccess} width="25px" />
            : <CircularPercentage
              fontColor={"#1E1A3A"}
              strokeColour={data?.progressBarColor}
              percentage={data?.percentage || 0}
            />}</div>
        </div>
        {/* {data?.percentage ? <><ProgressBar
          width={data?.percentage}
          bgColor={data?.progressBarColor}
        />
          <div className="TaskCard__Stats--Completed">{data?.completedText}</div></> : null} */}
      </div>

      <div className="TaskCard__Action">
        <div className="TaskCard__Action--Info">{data?.taskInfo}</div>
        {data?.taskNote?.text && <div
          style={{ color: data?.taskNote?.color, fontSize: "1.4rem" }}
        >{data?.taskNote?.text}</div>}
        <div style={{
          display: "flex", justifyContent: "space-between",
          marginTop: "16px"
        }}>{data?.button && (
          <div
            className="TaskCard__Action--Button"

            // style={{background:data?.isDisable?"#E89DB3":"#54881C"}}
            onClick={() => directDeepLink(data?.button?.deeplink, SOURCE_WEBVIEW)}
          >
            {data?.button?.text}
          </div>
        )}
          <div className="TaskCard__Stats__Top__Points">
            <img src={Coin} alt="" />
            <span className="TaskCard__Stats__Top__Points--Count">
              {data?.points}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TaskCard;
