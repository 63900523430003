import React from 'react';
import './Ranking.scss';

function Ranking(props) {
  const { data } = props;
  return (
    <div className="Ranking">
      <div className="Ranking__Headers">
        <div className="Ranking--rank">Rank</div>
        <div className="Ranking--institute">Institute</div>
        <div className="Ranking--points">Points</div>
      </div>

      {data?.map((data) => {
        return (
          <div
            key={data?.rank}
            className="Ranking__Block"
            style={{
              background:
                'linear-gradient:(148.05deg, rgba(255, 255, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%, rgba(255, 255, 255, 0) 100%)',
              filter: 'dropShadow :(0px 2px 4px rgba(0, 0, 0, 0.2))',
              backdropFilter: 'blur(40px)',
              /* Note: backdrop-filter has minimal browser support */

              borderRadius: '16px',
              // background:

              // data?.myOrg ? "#F7F7F7" : "#FFFFFF",
            }}
          >
            <div
              className="Ranking--rank"
              style={{ color: data?.myOrg ? '#88D759' : '' }}
            >
              {data?.rankImg ? <img src={data?.rankImg} /> : data?.rank}
            </div>
            <div
              className="Ranking--institute"
              style={{ color: data?.myOrg ? '#88D759' : '' }}
            >
              {data?.orgName}
            </div>
            <div
              className="Ranking--points"
              style={{ color: data?.myOrg ? '#88D759' : '' }}
            >
              {data?.points}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default Ranking;
