import React, { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';

import { GET, makeApiCal } from '../../utils';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './styles/home.scss';

import Loader from '../Loader/Loader';

import HomeSlide from './Slides/HomeSlide';
import StatsSlide from './Slides/StatsSlide';
import RewardSlide from './Slides/RewardSlide';
import ToolsSlide from './Slides/ToolsSlide';
import MilestoneSlide from './Slides/MilestoneSlide';
import CheckListSlide from './Slides/CheckListSlide';
import WelcomeSlide from './Slides/WelcomeSlide';
import FreedomHours from './Slides/FreedomHours';
import SliderButton from '../../Resources/images/back.svg';

import { webEngageSaleServiceRevamp } from '../../webEngage/service';
import * as weEvents from "../../webEngage/event";

import slideBackground from '../../Resources/images/carnival/slideBackground.png';
// import slideBackground2 from "../../Resources/images/summerSuperSaverSale/slideBackground2.png"
// import slideBackground3 from "../../Resources/images/summerSuperSaverSale/slideBackground3.png"
// import slideBackground4 from "../../Resources/images/summerSuperSaverSale/slideBackground4.png"
// import slideBackground5 from "../../Resources/images/summerSuperSaverSale/slideBackground5.png"
// import slideBackground6 from "../../Resources/images/summerSuperSaverSale/slideBackground6.png"

function Home() {
  const sliderRef = useRef(null);
  const [err, catchError] = useState(false);
  const [apiData, setApiData] = useState();
  const [loading, setLoading] = useState(true);
  const [background, setBackground] = useState(0);
  // const backgroundImg =apiData?.peakDayCard? [
  //   slideBackground1, slideBackground2, slideBackground3, slideBackground4, slideBackground5, slideBackground6
  // ] : [
  //   slideBackground1, slideBackground2, slideBackground4, slideBackground5, slideBackground6
  // ]
  useEffect(() => {
    const action = {
      method: GET,
      route: 'v2/course/campaign/homeCard',
      storeData: setApiData,
      catchError: catchError,
    };
    makeApiCal(action);
  }, []);

  useEffect(() => {
    if (apiData) {
      setLoading(false);

      let userData = {
        ...apiData.campaignOrg
      }
      localStorage.setItem("saleData", JSON.stringify(userData));
      // setTeamId(apiData?.campaignOrg?.teamId);
    }
  }, [apiData]);

  // useEffect(() => {
  //    if (teamId) localStorage.setItem('TeamId', teamId);
  // }, [teamId]);
  // console.log('zyx', apiData);
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    autoplay: false,
    autoplaySpeed: 5000,
    cssEase: 'linear',
    dotsClass: 'sliderDots slick-dots',
    touchThreshold: 500,
    afterChange: (e) => {
      // console.log(e);
      setBackground(e);
    },
  };

  return loading ? (
    <Loader />
  ) : (
    <div
      style={{
        background: `url(${slideBackground}) no-repeat`,
        // backgroundSize: "100% 100%"
      }}
      className="Home"
    >
      {/* <lottie-player
        src={'https://assets7.lottiefiles.com/packages/lf20_nqozkoiy.json'}
        // src={json}
        background="transparent"
        speed="1"
        style={{
          position: 'absolute',
          top: '-175px',
          height: '100%',
          left: '0',
        }}
        loop
        autoplay
      /> */}
      <Slider {...settings} ref={sliderRef}>
        {(apiData?.homeMileStone || apiData?.homeCard) && (
          <HomeSlide
            data={apiData?.homeMileStone}
            homemilestone
            isQualified={apiData?.isQualified}
            campaignOrg={apiData?.campaignOrg}
            isNorth={apiData?.campaignOrg?.isNorth}
          />
        )}
        {apiData?.statCard && (
          <StatsSlide
            data={apiData?.statCard}
            point={apiData?.campaignOrg?.totalPoints}
            isNorth={apiData?.campaignOrg?.isNorth}
          />
        )}
        {apiData?.peakDayCard && (
          <MilestoneSlide
            data={apiData?.peakDayCard}
            isNorth={apiData?.campaignOrg?.isNorth}
          />
        )}
        {apiData?.awardsCard && (
          <RewardSlide
            data={apiData?.awardsCard}
            isNorth={apiData?.campaignOrg?.isNorth}
          />
        )}
        {apiData?.toolsCard && (
          <ToolsSlide
            data={apiData?.toolsCard}
            isNorth={apiData?.campaignOrg?.isNorth}
          />
        )}
        {apiData?.tutorOfTheDay && (
          <CheckListSlide data={apiData?.tutorOfTheDay} />
        )}
        {apiData?.freedomhoursclasscard && (
          <FreedomHours data={apiData?.freedomhoursclasscard} />
        )}

      </Slider>
      <div className="Home__SliderButton">
        <img
          onClick={() => sliderRef.current.slickPrev()}
          className="Home__SliderButton--Prev"
          src={SliderButton}
          alt=">"
        />
        <img
          onClick={() => sliderRef.current.slickNext()}
          className="Home__SliderButton--Next"
          src={SliderButton}
          alt=">"
        />
      </div>
    </div>
  );
}

export default Home;
