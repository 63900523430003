import React from 'react';

import BannerImage from '../../../Resources/images/LandingScreen.png';
import Teacher from '../../../Resources/images/Teacher.svg';
import CardFooter from '../CardFooter/CardFooter';
import pointsCardHeaderImg from "../../../Resources/images/baisakhi/pointsCardHeaderImg.svg";
import lights from './lights.gif'
 const lottieStyle = {
   position: "absolute",
   backgroundColor: "#2B1451",
   height: "100%",
 };
 const lottie = () => {
   return (
     <div style={lottieStyle}>
       <lottie-player
         src="https://assets6.lottiefiles.com/packages/lf20_6ef4hgzm.json"
         background="transparent"
         speed="1"
         style={{ width: "100%", height: "100%" }}
         loop
         autoplay
       ></lottie-player>
     </div>
   );
 };

function WelcomeSlide(props) {
  const { data } = props;

  const footer = {
    text: null,
    button: {
      text: 'Teachers’ Week Celebration',
      bgColor: '#FDCA00',
    },
    deepLink: data?.deeplink,
  };

  return (
    <div
      className="Slide"
      style={{
        backgroundColor: '#B4B4ED',
        // backgroundImage: `url(${BannerImage})`,
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: '8px',
        margin: '2px',
        border : '2px solid rgb(253, 202, 0)'
      }}
    >
      <div className="Slide__Landing">
        <img src={Teacher} alt="" />
      </div>

      <CardFooter data={footer} />
    </div>
  );
}

export default WelcomeSlide;
