import React from 'react';

import PeaknStatsCard from '../PeakAndStatsCard';
import StatsCard from "../StatsCard"
import StatsSection from '../StatsSection/StatsSection';
import CardFooter from '../CardFooter/CardFooter';
import holiTaskImg from '../../../Resources/images/holiTaskImg.png';
import BannerImage from '../../../Resources/images/Points.png';
import KitesHeading from "../../../Resources/images/baisakhi/Kites.svg";
import SingleRibbon from "../../../Resources/images/baisakhi/SingleRibbon.svg";
import StatsSlideImage from "../../../Resources/images/summerSuperSaverSale/statsSlideImage.png"
import statsSlideTopBg from "../../../Resources/images/summerSuperSaverSale/statsSlideTopBg.png"
import BgRainCard from "../../../Resources/images/BgRainCard.png"
import CheckListSlideImg from "../../../Resources/images/carnival/checkListSlideImg.png"
import Mogu from "../../../Resources/images/baisakhi/MoguDesign.svg";
import ToolsSlideImg from "../../../Resources/images/carnival/toolsSlideImg.png"
import lights from './lights.gif'

 const lottieStyle = {
   position: "absolute",

   height: "100%",
   right: '-3%',
   bottom: "0px",
   width: "50%",
 }; 
const lottie = () => {
   return (
     <div style={lottieStyle}>
       <lottie-player
         src="https://assets6.lottiefiles.com/packages/lf20_6ef4hgzm.json"
         background="transparent"
         speed="1"
         style={{ width: "100%", height: "100%",  }}
         loop
         autoplay
       ></lottie-player>
     </div>
   );
 };
function StatsSlide(props) {
  const { data, point,isNorth } = props;

  const banner = {
    text: 'Complete Tasks & Earn Points!',
    image: BannerImage,
    gridTemplateColumns: "1.6fr 1fr",
  };

  const imagePostion ={
    right: "13%",
    top: "-9%",
    width: "13rem",
  }

  const otherBannerStyle = {
    // paddingTop: "1.4rem"
    background: `url(${statsSlideTopBg}) no-repeat`
  }

  const footer = {
    text: null,
    button: {
      text: 'See all tasks',
      bgColor: '#FDCA00',
    },
    deepLink: data?.deeplink,
  };
let statsArr=[
  data?.stats?.appDownloads,
  data?.stats?.notifications,
  data?.stats?.coupons,
  data?.stats?.courses,
]
  return (
    <div
      className="Slide"
      style={{
        backgroundImage : `url(${BgRainCard})`,
        backgroundSize : "cover",
        backgroundPosition :"center",
        // backgroundColor: '#071D32',
        // boxShadow: '0px 2px 4px rgba(0, 0, 0, 0.16)',
        borderRadius: "8px",
        margin: "2px",
        // border : '2px solid rgb(253, 202, 0)'
      }}
    >
      {/* <img
				src={lights}
				width="100%"
				style={{
					width: '167%',
					marginLeft: '65%',
				}}
			/> */}
      <PeaknStatsCard
        data={banner}
        image = {StatsSlideImage}
        rightBkgImg={isNorth ? Mogu : ''}
         imagePostion={imagePostion}
        // backgroundImage={ToolsSlideLeftImg}
        // imagePostion={imagePostion}
        // peakLeftCard={peakLeftCard}
        otherBannerStyle={otherBannerStyle}
        // topRightImg={SingleRibbon}
         // topRightImg={SingleRibbon}
         text="Complete task & Earn Points !"
         margin={-83}
       
      >
        
        {/* {lottie()} */}
      </PeaknStatsCard>
      <StatsCard data={statsArr} point={point} />
      {/* <StatsSection data={data?.stats} /> */}
      {/* <CardFooter data={footer} /> */}
    </div>
  );
}

export default StatsSlide;
