import React from 'react';
import ClaimedRewards from './ClaimedRewards';
import NoRewards from './NoRewards';

function RewardsBody(props) {
  const { data } = props;
  return data?.length ? <ClaimedRewards data={data} /> : <NoRewards />;
}

export default RewardsBody;
