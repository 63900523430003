import React, { useEffect, useState } from 'react';
import { makeApiCal } from '../../utils';

import Header from '../UI/Header';
import RewardsBody from './RewardsBody';
import ContentLoader from '../Loader/ContentLoader';

const RewardsPage = (props) => {
  const [apiData, setapiData] = useState();
  const [loading, updateLoading] = useState(true);

  useEffect(() => {
    makeApiCal({
      method: 'GET',
      route: 'v2/course/campaign/tutor/rewards',

      storeData: (data) => {
        setapiData([...data]);
      },
      catchError: (err) => {
        console.log(err);
      },
    });
  }, []);

  useEffect(() => {
    if (apiData) {
      updateLoading(false);
    }
  }, [apiData]);

  return (
    <div className="RewardsPage">
      <Header heading="Your Rewards" />
      {loading ? <ContentLoader /> : <RewardsBody data={apiData} />}
    </div>
  );
};

export default RewardsPage;
