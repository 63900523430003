import "./StatsCard.scss";
import taskIcon from "../../../Resources/images/taskIcon.png";
import TaskSuccessBig from "../../../Resources/images/TaskSuccessBig.png";
import {
  CircularProgressbarWithChildren,
  buildStyles,
} from "react-circular-progressbar";
import { directDeepLink, SOURCE_HOME } from "../../../utils";
import coin from "../../../Resources/images/Coin.svg";

export default function StatsCard(prop) {
  return (
    <div className="statsContainer">
      {/* <div className="statsPointContainer">
            <div className="statsHeader">Complete and Win</div>
            <div className="stattsPoints">{prop?.point}{" "}pts <img width="13px" src={coin}/></div>
        </div> */}
      <div className="statsCardContainer">
        {prop?.data?.map((el) => {
          // console.log(el);
          return (
            <div
              className="statsCard"
              style={{ background: el.bgColor }}
              onClick={() => directDeepLink(el.button.deeplink, SOURCE_HOME)}
            >
              <img
                style={{
                  position: "absolute",
                  right: "1px",
                }}
                src={el.bgIcon}
              />
              <div className="statsCardText">
                <div
                  style={{
                    fontSize: "1.7rem",
                    whiteSpace: "nowrap",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    color: el?.color,
                  }}
                >
                  {el?.text}
                </div>
              </div>
              <div
                style={{
                  fontSize: "11px",
                  paddingTop: "5px",
                  color: el.color,
                }}
              >
                <span
                  style={{
                    // color: el.subTextColor,
                    fontSize: "11px",
                  }}
                >
                  {el?.total}
                </span>
                {el?.subText}
                <img className="statsCardArrowIcon" src={el?.arrowIcon} />
              </div>
              <div
                style={{
                  width: "80px",
                  margin: "9px auto",
                }}
              >
                {el.percentage == 100 ? (
                  <img
                    style={{ width: "9rem", padding: ".2rem 0px" }}
                    src={TaskSuccessBig}
                  />
                ) : (
                  <CircularProgressbarWithChildren
                    styles={buildStyles({
                      textSize: "16px",
                      pathTransitionDuration: 2,
                      pathColor: "#AC5DD9",
                      textColor: "#777777",
                      trailColor: "#EBEBEB",
                    })}
                    strokeWidth={12}
                    value={el.percentage || 0}
                  // text={el.percentage + "%"}
                  >
                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          position: "absolute",
                          width: "5rem",
                          top: "-16px",
                          "font-size": "2rem",
                          left: "-22px",
                          "text-align": "center",
                        }}
                      >
                        {el.percentage} %
                      </div>
                    </div>
                  </CircularProgressbarWithChildren>
                )}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
}
