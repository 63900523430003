import React, { useState, useEffect } from 'react';

import LightsImage from '../../../Resources/images/Winter.png';
import PeaknStatsCard from '../PeakAndStatsCard';
import HomeSlideImg from '../../../Resources/images/carnival/freedomSlideImg.png';

import BgRainCard from '../../../Resources/images/freedomBgSide.png';

import ProgressBar from '../../UI/ProgressBar/ProgressBar';
import lights from './lights.gif';

function FreedomHours(props) {
	const { data } = props;
	const [time, setTime] = useState(null);
	const [timeStamp, setTimeStamp] = useState(data?.endTime?.[0]);

	useEffect(() => {
		const dayBase = 60 * 60 * 24;
		const hourBase = 60 * 60;
		const minuteBase = 60;
		const days = Math.floor(timeStamp / dayBase),
			hours = Math.floor((timeStamp % dayBase) / hourBase),
			minutes = Math.floor((timeStamp % hourBase) / minuteBase),
			seconds = timeStamp % minuteBase;
		// if (increment) {
		setTime(
			`${hours ? `${hours}:` : ''} ${minutes ? `${minutes}: ${seconds}` : ''}`
		);
		setTimeout(() => {
			setTimeStamp((prevData) => prevData - 1);
		}, 1000);
		// }
	}, [timeStamp]);

	const banner = {
		text: 'Celebration Hours',
		image: LightsImage,
		gridTemplateColumns: '2fr 1fr',
	};
	let imagePostion = {
		position: 'absolute',
		right: '-16px',
		bottom: '-52px',
		width: '161%',
	};

	const peakLeftCardImg = {
		width: '90%',
	};
	const otherBannerStyle = {
		// background: `url(${homeSlideTopBg}) no-repeat`,
		backgroundPosition: '-5px -5px',
		backgroundSize: '103% 100% !important',
	};

	const lottieStyle = {
		position: 'absolute',
		backgroundColor: '#2B1451',
		height: '100%',
		paddingTop: '6%',
		display: 'flex',
		alignItems: 'flex-end',
	};

	const lottie = () => {
		return (
			<div style={lottieStyle}>
				<lottie-player
					src={
						'https://cp-assets-web.s3.ap-south-1.amazonaws.com/campaignJSON/8438-mr-cookie-drink2.json'
					}
					background="rgba(0, 0, 0, 0)"
					speed="1"
					loop
					autoplay
				></lottie-player>
			</div>
		);
	};

	return (
		<div
			className="Slide"
			style={{
				backgroundImage: `url(${BgRainCard})`,
				backgroundSize: "100% 100%",
				backgroundPosition: 'center',
				borderRadius: '8px',
				margin: '3px',
				// border: '2px solid rgb(253, 202, 0)',
			}}
		>
			{/* <img
				src={lights}
				width="100%"
				style={{
					width: '167%',
					marginLeft: '65%',
				}}
			/> */}
			<PeaknStatsCard
			style={{
				fontSize: "29px",
				marginTop: "33px",
				marginLeft: "20px",
				textAlign: "initial",
				width: "100%",
				lineHeight: "28px",
				color:"white"
			  }}
				otherBannerStyle={otherBannerStyle}
				text="Celebration Hours"
				data={banner}
				slide
				imagePostion={imagePostion}
				// image={HomeSlideImg}
				peakLeftCardImg={peakLeftCardImg}
				isHomeSlide
			>
				<span
					style={{
						fontSize: '17px',
						marginLeft: '23px',
						marginTop: '-22px',
						color: 'white',
					}}
				>
					ENDS IN:
				</span>
				<span
					style={{
						fontSize: '17px',
						marginLeft: '-135px',
						marginTop: '-22px',
						color: '#F78C64',
					}}
				>
					{time}
				</span>
			</PeaknStatsCard>
			<div
				style={{
					'padding-top': '13px',

					'height': '100%',
					marginTop: '-10%',
				}}
			>
				<div className="FreedomSlide__Section">
					<div className="FreedomSlide__Section__Revenue">
						<div className="FreedomSlide__Section__Revenue--Label">
							Your Revenue
						</div>
						<div className="FreedomSlide__Section__Revenue--Value">
							{data?.total}
						</div>
					</div>
					<ProgressBar
						width={data?.progressBar}
						background={data?.progressBarColor}
						thick={20}
					/>
					<div className="FreedomSlide__Section__Goal">
						<div className="FreedomSlide__Section__Goal--Completed">
							{data?.completedPercent}
						</div>
						<div className="FreedomSlide__Section__Goal--Target">
							Goal: {data?.goal}
						</div>
					</div>
					<div className="FreedomSlide__Section--Divider" />
				</div>

				<div className="FreedomSlide__Section__Promo">
					<img src={data?.winningPromoImages} alt="promo" />
				</div>
			</div>
		</div>
	);
}

export default FreedomHours;
