import React, { forwardRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

const Navbar = forwardRef((props, ref) => {
  const { data, activeTab, updateActiveTab, scrollNav, colorData } = props;
  const history = useHistory();
  return (
    <div
    style={{
      // background:window.location.pathname.includes("milestones") ? "#E2F4E9" : "#FEEAC7"
    }}
    className="navBarContaner">
    <div className="NavBar"
    // style={{
    //   background:`linear-gradient(359.99deg, #EE3155 -55.8%, #EA3054 -21.55%, #DD2C4F 12.66%, #C92648 53.35%, #AC1E3E 99.13%, #A81D3D 136.04%)`
    // }}
    ref={ref}>
      {data?.map((item, i) => {
        const isActive = item?.key === activeTab?.key;
        return (
          <span
            id={item?.key}
            className="NavBar__MenuItem"
            key={item?.key}
            style={{
              color: isActive ? "#F78C64" : "#3D2072",
              // borderBottom: isActive ? '4px solid #212121' : 'none',
              background: isActive ? "#3D2072" : "",
              "box-shadow": isActive ? "0px 4px 4px rgb(0 0 0 / 25%)" : "",
              "border-radius": isActive ? "21.5px" : "",
              // marginLeft: i === 0 ? "70px" : "",
            }}
            onClick={() => {
              if(item?.key=="checklist"){
                scrollNav(3)
              }else if(item?.key=="leaderboards"){
                scrollNav(-3)
              }
              
              console.log(item?.key)
              history.push(item?.key);
              // updateActiveTab(item);
            }}
          >
            {item?.name}
          </span>
        );
      })}
    </div>
    </div>
  );
})

export default Navbar;
