import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { GET, getQuery, makeApiCal } from '../../utils';
import { useSwipeable } from 'react-swipeable';
import './layout.scss';

import Content from './Content';
import Drawer from './Drawer';
import Navbar from './Navbar';
import ContentLoader from '../Loader/ContentLoader';
import DrawerShimmer from '../Loader/DrawerShimmer';
import layoutBackGround from '../../Resources/images/beatHeat/detailsBackground.png';
import LeaderBoardTopLayout from '../../Resources/images/summerSuperSaverSale/leaderBoardTopLayout.png';
import TaskTopLayout from '../../Resources/images/summerSuperSaverSale/taskTopLayout.png';
import MilestoneTopLayout from '../../Resources/images/summerSuperSaverSale/milestoneTopLayout.png';
import RewardsTopLayout from '../../Resources/images/summerSuperSaverSale/rewardsTopLayout.png';
import ToolsTopLayout from '../../Resources/images/summerSuperSaverSale/toolsTopLayout.png';
import CheckListTopLayout from '../../Resources/images/summerSuperSaverSale/checkListTopLayout.png';
import LeaderBoardBg from '../../Resources/images/summerSuperSaverSale/leaderBoardBg.png';
import TaskBg from '../../Resources/images/summerSuperSaverSale/taskBg.png';
import MilestoneBg from '../../Resources/images/summerSuperSaverSale/milestoneBg.png';
import RewardsBg from '../../Resources/images/summerSuperSaverSale/rewardsBg.png';
import ToolsBg from '../../Resources/images/summerSuperSaverSale/toolsBg.png';
import CheckListBg from '../../Resources/images/summerSuperSaverSale/checkListBg.png';
import LayoutFooter from '../../Resources/images/layoutFooter.png';
import DrawerTopIcon from '../../Resources/images/IPL/drawerTopIcon.png';

function Layout(props) {
  const layoutTopArr = [
    {
      topBg: LeaderBoardTopLayout,
      orgNameColor: '#FFFFFF',
      earningColor: '#FFFFFF',
      goalColor: '#FFFFFF',
      pointsColor: '#EC2F1A',
      navbarColor: '#EC2F1A',
      navText: '#FFFFFF',
      bg: LeaderBoardBg,
      progressBarColor: '#EC2F1A',
    },
    {
      topBg: MilestoneTopLayout,
      orgNameColor: '#593202',
      earningColor: '#593202',
      goalColor: '#593202',
      pointsColor: '#683D00',
      navbarColor: '#F5B100',
      navText: '#593202',
      bg: MilestoneBg,
      progressBarColor: '#F5B100',
    },
    {
      topBg: TaskTopLayout,
      orgNameColor: '#FFFFFF',
      earningColor: '#FFFFFF',
      goalColor: '#FFFFFF',
      pointsColor: '#C50941',
      navbarColor: '#C50941',
      navText: '#FFFFFF',
      bg: TaskBg,
      progressBarColor: '#C50941',
    },
    {
      topBg: RewardsTopLayout,
      orgNameColor: '#000000',
      earningColor: '#000000',
      goalColor: '#000000',
      pointsColor: '#683D00',
      navbarColor: '#FF8C01',
      navText: '#000000',
      bg: RewardsBg,
      progressBarColor: '#FF8C01',
    },
    {
      topBg: ToolsTopLayout,
      orgNameColor: '#FFFFFF',
      earningColor: '#FFFFFF',
      goalColor: '#FFFFFF',
      pointsColor: '#33843E',
      navbarColor: '#3A9645',
      navText: '#FFFFFF',
      bg: ToolsBg,
      progressBarColor: '#33843E',
    },
    {
      topBg: CheckListTopLayout,
      orgNameColor: '#FFFFFF',
      earningColor: '#FFFFFF',
      goalColor: '#FFFFFF',
      pointsColor: '#3F34EB',
      navbarColor: '#3F34EB',
      navText: '#FFFFFF',
      bg: CheckListBg,
      progressBarColor: '#3F34EB',
    },
  ];
  const history = useHistory();
  const location = useLocation();
  let navRef = useRef();
  let drawerRef = useRef();
  const [apiData, setApiData] = useState();

  const [nav, setNav] = React.useState(true);

  const navBar = [
    ...(apiData?.rankings
      ? [
          {
            key: 'leaderboards',
            name: 'Leaderboard',
            drawerColor: '#2B0D2C',
          },
        ]
      : []),
    ...(apiData?.peakDay
      ? [
          {
            key: 'milestones',
            name: 'Milestones',

            drawerColor: 'rgba(28, 55, 103, 1)',
          },
        ]
      : []),
    ...(apiData?.statCard
      ? [
          {
            key: 'tasks',
            name: 'Tasks',
            drawerColor: '#071D32',
          },
        ]
      : []),
    ...(apiData?.awardsCard
      ? [
          {
            key: 'rewards',
            name: 'Rewards',
            drawerColor: '#4F1D6B',
          },
        ]
      : []),
    ...(apiData?.toolsCard
      ? [
          {
            key: 'tools',
            name: 'Tools',
            drawerColor: '#1E3D7E',
          },
        ]
      : []),
    ...(apiData?.checklist
      ? [
          {
            key: 'checklist',
            name: 'Checklist',
            drawerColor: '#1E3D7E',
          },
        ]
      : []),
  ];
  // useEffect(() => {
  //   console.log(navBar);
  // }, [navBar]);

  const [activeTab, updateActiveTab] = useState();
  const [activeIndex, updateActiveIndex] = useState();

  const [loading, updateLoading] = useState(true);
  const [err, catchError] = useState(false);

  const getData = () => {
    const action = {
      method: GET,
      route: 'v2/course/campaign/teachersDayInfo',
      storeData: setApiData,
      catchError: catchError,
    };
    makeApiCal(action);
  };

  useEffect(() => {
    getData();

    const tab = props?.match?.params?.tab;

    const scroller = new Promise((resolve, reject) => {
      setTimeout(() => {
        scrollNav(3);
        resolve();
      }, 1000);
    });

    scroller
      .then(() => {
        if (tab != 'checklist') {
          setTimeout(() => {
            scrollNav(-3);
          }, 500);
        }
      })
      .then(() => {
        if (tab === 'milestones2' || tab === 'rewards' || tab === 'tools') {
          setTimeout(() => {
            scrollNav(3);
          }, 1000);
        }
      });
  }, []);

  useEffect(() => {
    const tab = props?.match?.params?.tab;
    if (apiData) {
      updateLoading(false);
      if (tab) {
        console.log(tab);
        let tabIndex = navBar.findIndex((item) => item?.key === tab);
        updateActiveIndex(tabIndex);
        updateActiveTab(navBar[tabIndex]);
        //navBar?.filter((item) => item.key === tab)
      }
    }
  }, [apiData, err]);

  // useEffect(() => {
  //   if (activeTab&&history?.action!="POP") {
  //     history.push(`/details/${activeTab?.key}`);
  //     updateActiveIndex(
  //       navBar.findIndex((item) => item?.key === activeTab?.key)
  //     );
  //   }
  // }, [activeTab]);

  useEffect(() => {
    let tabName = history?.location?.pathname?.split('/')[2];
    let tabIndex = navBar.findIndex((item) => item?.key === tabName);
    updateActiveTab(navBar[tabIndex]);
    updateActiveIndex(tabIndex);
  }, [location]);

  const drawerData = {
    ...apiData?.campaignOrg,
    bgColor: activeTab?.drawerColor,
    hanleClick: () => history.push('/rewards'),
  };

  const changeTab = (value) => {
    if (activeIndex === navBar?.length - 1 && value > 0) {
      history.push(`${navBar[0].key}`);
      // updateActiveTab(navBar[0]);
      scrollNav(-3);
    } else if (activeIndex === 0 && value < 0) {
      history.push(`${navBar[navBar?.length - 1].key}`);
      // updateActiveTab(navBar[navBar?.length - 1]);
      scrollNav(3);
    } else {
      history.push(`${navBar[activeIndex + value].key}`);
      // updateActiveTab(navBar[activeIndex + value]);
      scrollNav(value);
    }
  };

  const scrollNav = (value) => {
    const scrollWidth = window?.innerWidth * (Math.abs(value) * 0.34);
    if (value > 0) {
      navRef?.current?.scrollBy(scrollWidth, 0);
    } else if (value < 0) {
      navRef?.current?.scrollBy(-scrollWidth, 0);
    }
  };

  const swipeActions = useSwipeable({
    onSwipedLeft: () => changeTab(1),
    onSwipedRight: () => changeTab(-1),
  });
  // console.log(activeTab,activeIndex)
  return loading ? (
    <div className="Layout">
      <DrawerShimmer data={drawerData} />
      {nav && (
        <Navbar
          data={navBar}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
          scrollNav={scrollNav}
          ref={navRef}
        />
      )}
      <div {...swipeActions}>
        <ContentLoader />
      </div>
    </div>
  ) : (
    <div
      // style={{background:window.location.pathname.includes("milestones")?
      //         `url(${layoutBackGround}) no-repeat`:"#FEEAC7"}}

      className="Layout"
    >
      <Drawer data={drawerData} />

      {nav && (
        <Navbar
          data={navBar}
          activeTab={activeTab}
          updateActiveTab={updateActiveTab}
          scrollNav={scrollNav}
          ref={navRef}
          // colorData={layoutTopArr[activeIndex]}
        />
      )}

      <div>
        <Content
          data={apiData}
          activeTab={activeTab}
          swipeActions={swipeActions}
          callMainApi={getData}
          setNav={setNav}
        />
      </div>
      {/* <div className="layoutBottom">
        <img
        style={{width:"100%"}}
        src={LayoutFooter}/>
      </div> */}
    </div>
  );
}

export default Layout;
