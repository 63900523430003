import React from "react";
import { directDeepLink, SOURCE_WEBVIEW } from "../../../utils";
import "./insight.scss";

function InsightCard({ data }) {
	return (
		<div
			className="InsightCard"
			onClick={() => directDeepLink(data?.url, SOURCE_WEBVIEW, true)}
		>
			<img src={data?.image} alt="icon" />
		</div>
	);
}

export default InsightCard;
